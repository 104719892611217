import React, { createContext, useContext, useState } from "react";
import { PaymentResponse, PaymentStatus, PaymentType, WalletModel } from "../components/payment/PaymentApi";
import { UserInfo } from "../components/signup/SignUpApi";
import { Pocket } from "../components/payment/SubscriptionApi";
import { convertFloatToDinero } from "../utils/money";
import { Currency } from "dinero.js";
import logger, { LogTagKey } from "../utils/logger";
import useApi from "../hooks/useApi";
import { formatUtcAsLocalDate, formatUtcAsLocalDateTimeByEOD } from "../utils/date";
import { useStickyState } from "../hooks/useStickyState";
import AuthService from "../components/auth/AuthService";
import { UserTag } from "../components/auth/TagsApi";
import { trackLogin } from "../utils/analytics";
import tiktokPixel from "../utils/analytics/tiktokPixel";
import { useQuery } from "hooks/useQuery";
import { KycUserProps } from "../components/score/KycApi";

export interface BreadcrumbData {
  title: string;
  url: string;
}

export interface SignedInContextInterface {
  wallet: WalletModel | undefined;
  userInfo: UserInfo | undefined;
  kyc: KycUserProps | undefined;
  setUserInfo: (info: UserInfo) => void;
  pocket: Pocket | undefined;
  errorMessage: string | undefined;
  setErrorMessage: (errorMessage: string | undefined) => void;
  getNextPaymentDate: () => string;
  getNextPaymentDateTime: () => string;
  getLastPaymentDateTime: () => string;
  previousPage: BreadcrumbData | undefined;
  setPreviousPage: (bc: BreadcrumbData) => void;
  hasOverduePayment: (bitsTurbo?: boolean) => boolean;
  loadWallet: () => Promise<WalletModel | null>;
  loadUserInfo: () => void;
  loadPocket: () => void;
  loadKyc: () => void;
  waitAndReloadWallet: (waitMs?: number) => void;
  isWalletLoading: boolean;
  userTags: UserTag[] | undefined;
  setUserTags: (tags: UserTag[] | undefined) => void;
  mobileSidebar: boolean;
  setMobileSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  superChargeModal: boolean;
  setSuperChargeModal: React.Dispatch<React.SetStateAction<boolean>>;
  tranchesCount: number;
  setTranchesCount: (tranchesCount: number) => void;
  isPayInThree: boolean;
  accessToken: string | null;
  setAccessToken: (token: string | null) => void;
  phoneVerificationIdentifier: string | null;
  setPhoneVerificationIdentifier: (identifier: string | null) => void;
}

export const defaultSignedInContextValues: SignedInContextInterface = {
  wallet: undefined,
  userInfo: undefined,
  kyc: undefined,
  setUserInfo: () => {},
  pocket: undefined,
  errorMessage: undefined,
  setErrorMessage: () => {},
  getNextPaymentDate: () => "",
  getNextPaymentDateTime: () => "",
  getLastPaymentDateTime: () => "",
  previousPage: undefined,
  setPreviousPage: () => {},
  hasOverduePayment: () => false,
  loadWallet: () => Promise.resolve(null),
  loadUserInfo: () => {},
  loadPocket: () => {},
  loadKyc: () => {},
  waitAndReloadWallet: () => {},
  isWalletLoading: false,
  userTags: undefined,
  setUserTags: () => {},
  mobileSidebar: false,
  setMobileSidebar: () => null,
  isVisible: true,
  setIsVisible: () => null,
  superChargeModal: false,
  setSuperChargeModal: () => null,
  tranchesCount: 0,
  setTranchesCount: () => {},
  isPayInThree: false,
  accessToken: null,
  setAccessToken: () => {},
  phoneVerificationIdentifier: null,
  setPhoneVerificationIdentifier: () => {},
};

export const SignedInContext = createContext<SignedInContextInterface>(defaultSignedInContextValues);

export const useBitsUserContext = () => useContext(SignedInContext);

const SignedInContextProvider: React.FC = ({ children }) => {
  const [wallet, setWallet] = useState<WalletModel>();
  const [isWalletLoading, setWalletLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [pocket, setPocket] = useState<Pocket>();
  const [userTags, setUserTags] = useState<UserTag[]>();
  const [kyc, setKyc] = useState<KycUserProps>();
  const [fundedPayments, setFundedPayments] = useState<PaymentResponse[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [previousPage, setPreviousPage] = useStickyState<BreadcrumbData>(null, "previousPage");
  const [mobileSidebar, setMobileSidebar] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [tranchesCount, setTranchesCount] = useState(0);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [phoneVerificationIdentifier, setPhoneVerificationIdentifier] = useState<string | null>(null);

  // Automatically open supercharge dialog from external link
  const openSuperchargeDialog = useQuery().get("applySupercharge")?.toLowerCase() === "true" ?? false;
  const [superChargeModal, setSuperChargeModal] = useState<boolean>(openSuperchargeDialog);

  const paymentApi = useApi().payment;
  const signUpApi = useApi().signUp;
  const subscriptionApi = useApi().subscription;
  const tagsApi = useApi().tags;
  const kycApi = useApi().kyc;

  const getNextPaymentDate = (): string => formatUtcAsLocalDate(wallet?.nextPaymentDate);

  const getNextPaymentDateTime = (): string => {
    const [firstPayment] = fundedPayments.sort((a, b) => (a.scheduledDate ?? "").localeCompare(b.scheduledDate ?? ""));
    return formatUtcAsLocalDateTimeByEOD(firstPayment?.scheduledDate ?? wallet?.nextPaymentDate);
  };

  const getLastPaymentDateTime = (): string => formatUtcAsLocalDateTimeByEOD(wallet?.lastPaymentDate);

  const hasOverduePayment = (bitsTurbo = false) =>
    !bitsTurbo
      ? wallet?.balance?.unpaidAmount != null
        ? !convertFloatToDinero({
            amount: wallet?.balance?.unpaidAmount,
            currency: wallet?.plan?.currency as Currency,
          }).isZero()
        : false
      : wallet?.bitsTurbo?.balance?.unpaidAmount != null
      ? !convertFloatToDinero({ amount: wallet?.balance?.unpaidAmount, currency: wallet?.plan?.currency as Currency })
      : false;

  const loadWallet = async (): Promise<WalletModel | null> => {
    if (AuthService.isAuthenticated()) {
      setWalletLoading(true);
      const loadPayments = paymentApi.getPayments([PaymentStatus.funded], [PaymentType.order, PaymentType.credit]);
      return Promise.all([paymentApi.getWallet(), tagsApi.getUserTags(), loadPayments])
        .then(([wallet, userTags, payments]) => {
          setWallet(wallet);
          setUserTags(userTags);
          setFundedPayments(payments.payments);
          return wallet;
        })
        .catch((e: any) => {
          setErrorMessage("Something went wrong! Please contact Bits for support.");
          logger.error(e, LogTagKey.TkPaymentSystem, "Payment System error fetching wallet");
          return null;
        })
        .finally(() => {
          setWalletLoading(false);
        });
    }
    return null;
  };

  const waitAndReloadWallet = (waitMs = 3000): void => {
    setWalletLoading(true);
    setTimeout(() => {
      loadWallet().then(() => {
        setWalletLoading(false);
      });
    }, waitMs);
  };

  const loadPocket = () => {
    if (AuthService.isAuthenticated()) {
      subscriptionApi
        .getOrCreatePocket()
        .then((pocket) => setPocket(pocket))
        .catch((e: any) => {
          setErrorMessage("Something went wrong! Please contact Bits for support.");
          logger.error(e, LogTagKey.TkSubscription, "Error fetching user pocket");
        });
    }
  };

  const loadUserInfo = () => {
    if (AuthService.isAuthenticated()) {
      signUpApi
        .fetchProfile()
        .then((userInfo) => {
          setUserInfo(userInfo);
          logger.setUser(userInfo.email, userInfo.membershipNumber);
          tiktokPixel.init(userInfo);
          trackLogin(userInfo);
        })
        .catch((e: any) => {
          setErrorMessage("Something went wrong! Please contact Bits for support.");
          logger.error(e, LogTagKey.TkAuth, "Error fetching user info");
        });
    }
  };

  const loadKyc = () => {
    if (AuthService.isAuthenticated()) {
      kycApi
        .getStatus()
        .then((kyc) => {
          setKyc(() => kyc);
        })
        .catch((e: any) => {
          if (e.error?.errorType !== "Not Found") {
            setErrorMessage("Something went wrong! Please contact Bits for support.");
            logger.error(e, LogTagKey.TkKyc, "Error fetching user kyc");
          }
        });
    }
  };

  return (
    <SignedInContext.Provider
      value={{
        wallet,
        userInfo,
        kyc,
        setUserInfo,
        loadUserInfo,
        loadPocket,
        loadKyc,
        pocket,
        errorMessage,
        setErrorMessage,
        getNextPaymentDate,
        getNextPaymentDateTime,
        getLastPaymentDateTime,
        previousPage,
        setPreviousPage,
        hasOverduePayment,
        loadWallet,
        waitAndReloadWallet,
        isWalletLoading,
        userTags,
        setUserTags,
        mobileSidebar,
        setMobileSidebar,
        isVisible,
        setIsVisible,
        superChargeModal,
        setSuperChargeModal,
        tranchesCount,
        setTranchesCount,
        isPayInThree: tranchesCount === 2,
        accessToken,
        setAccessToken,
        phoneVerificationIdentifier,
        setPhoneVerificationIdentifier,
      }}
    >
      {children}
    </SignedInContext.Provider>
  );
};

export default SignedInContextProvider;
