import { gql } from "@apollo/client";
import { MetadataItem } from "../store/product-details/Product.types";

const WebshopUserAddress = gql`
  fragment WebshopUserAddress on Address {
    id
    firstName
    lastName
    companyName
    streetAddress1
    streetAddress2
    city
    postalCode
    country {
      code
      country
      __typename
    }
    countryArea
    phone
    isDefaultBillingAddress
    isDefaultShippingAddress
    __typename
  }
`;

const WebShopCheckout = gql`
  fragment WebShopCheckout on Checkout {
    token
    id
    totalPrice {
      ...WebShopPrice
      __typename
    }
    subtotalPrice {
      ...WebShopPrice
      __typename
    }
    upfrontPrice {
      ...WebShopPrice
      __typename
    }
    billingAddress {
      ...WebshopUserAddress
      __typename
    }
    shippingAddress {
      ...WebshopUserAddress
      __typename
    }
    email
    availableShippingMethods {
      ...WebShopShippingMethod
      __typename
    }
    shippingMethod {
      ...WebShopShippingMethod
      __typename
    }
    shippingPrice {
      ...WebShopPrice
      __typename
    }
    lines {
      ...CheckoutLine
      __typename
    }
    isShippingRequired
    discount {
      currency
      amount
      __typename
    }
    discountName
    translatedDiscountName
    voucherCode
    __typename
  }
`;

const WebShopPrice = gql`
  fragment WebShopPrice on TaxedMoney {
    gross {
      amount
      currency
      __typename
    }
    net {
      amount
      currency
      __typename
    }
    __typename
  }
`;
const ProductVariant = gql`
  fragment ProductVariant on ProductVariant {
    id
    name
    sku
    quantityAvailable
    isAvailable
    pricing {
      onSale
      priceUndiscounted {
        ...WebShopPrice
        __typename
      }
      price {
        ...WebShopPrice
        __typename
      }
      __typename
    }
    attributes {
      attribute {
        id
        name
        __typename
      }
      values {
        id
        name
        value: name
        __typename
      }
      __typename
    }
    product {
      id
      name
      thumbnail {
        url
        alt
        __typename
      }
      thumbnail2x: thumbnail(size: 510) {
        url
        __typename
      }
      productType {
        isShippingRequired
        __typename
      }
      metadata {
        key
        value
      }
      __typename
    }
    images {
      url(size: 255)
    }
    __typename
  }
`;

const CheckoutLine = gql`
  fragment CheckoutLine on CheckoutLine {
    id
    quantity
    totalPrice {
      ...WebShopPrice
      __typename
    }
    variant {
      ...ProductVariant
      __typename
    }
    requiresShipping
    __typename
  }
`;

const WebShopShippingMethod = gql`
  fragment WebShopShippingMethod on ShippingMethod {
    id
    name
    price {
      currency
      amount
      __typename
    }
    __typename
  }
`;

const TOKEN_EXCHANGE_FIELDS = gql`
  ${WebshopUserAddress}
  ${WebShopPrice}
  ${ProductVariant}
  ${CheckoutLine}
  ${WebshopUserAddress}
  ${WebShopShippingMethod}
  ${WebShopCheckout}
  fragment WebshopUser on User {
    id
    email
    firstName
    lastName
    isStaff
    defaultShippingAddress {
      ...WebshopUserAddress
      __typename
    }
    defaultBillingAddress {
      ...WebshopUserAddress
      __typename
    }
    addresses {
      ...WebshopUserAddress
      __typename
    }
    checkout {
      ...WebShopCheckout
      __typename
    }
  }
`;

export interface TokenExchangeVars {
  token: string | null;
}

export const TOKEN_EXCHANGE_MUTATION = gql`
  ${TOKEN_EXCHANGE_FIELDS}
  mutation TokenExchange($token: String!) {
    tokenExchange(token: $token) {
      token
      accountErrors {
        field
        message
        __typename
      }
      user {
        ...WebshopUser
        __typename
      }
      __typename
    }
  }
`;

export interface StoreCountry {
  code: string;
  country: string;
}

export interface StoreAddress {
  id: string;
  firstName: string;
  lastName: string;
  companyName: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  postalCode: string;
  country: StoreCountry;
  countryArea: string;
  phone: string;
  isDefaultBillingAddress: boolean;
  isDefaultShippingAddress: boolean;
}

export interface StoreUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isStaff: boolean;
  defaultShippingAddress: StoreAddress;
  defaultBillingAddress: StoreAddress;
  addresses: [];
  checkout: StoreCheckout;
}

export interface AccountError {
  field: string;
  message: string;
  code: string;
}

export interface TokenExchangeData {
  tokenExchange: {
    token: string;
    accountErrors: AccountError[];
    user: StoreUser;
  };
}

export const CREATE_CHECKOUT_MUTATION = gql`
  ${WebShopPrice}
  ${ProductVariant}
  ${CheckoutLine}
  ${WebshopUserAddress}
  ${WebShopShippingMethod}
  ${WebShopCheckout}
  mutation CreateCheckout(
    $quantity: Int!
    $variantId: ID!
    $email: String!
    $shippingAddress: AddressInput!
    $billingAddress: AddressInput!
    $forceNew: Boolean!
  ) {
    checkoutCreate(
      input: {
        lines: [{ quantity: $quantity, variantId: $variantId }]
        email: $email
        shippingAddress: $shippingAddress
        billingAddress: $billingAddress
        forceNew: $forceNew
      }
    ) {
      checkout {
        ...WebShopCheckout
        __typename
      }
      checkoutErrors {
        field
        code
      }
      created
    }
  }
`;

export interface AddressInput {
  firstName: string;
  lastName: string;
  companyName: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  cityArea: string;
  postalCode: string;
  country: string;
  countryArea: string;
  phone: string;
}

export interface CreateCheckoutVars {
  quantity: number;
  variantId: string;
  email: string;
  shippingAddress: AddressInput;
  billingAddress: AddressInput;
  forceNew: boolean;
}

export interface CheckoutError {
  field: string;
  message: string;
  code: number;
}

export interface Money {
  amount: number;
  currency: string;
}

export interface TaxedMoney {
  gross: Money;
  net: Money;
}

export interface StorePaymentGateway {
  id: string;
  name: string;
}

export interface ShippingMethod {
  id: string;
  name: string;
  price: Money;
}

export interface VariantAttribute {
  attribute: {
    id: string;
    name: string;
  };
  values: {
    id: string;
    name: string;
    value: string;
  }[];
}

export interface Product {
  id: string;
  name: string;
  thumbnail: {
    url: string;
    alt: string;
  };
  thumbnail2x: {
    url: string;
  };
  productType: {
    isShippingRequired: boolean;
  };
  metadata?: MetadataItem[];
}

export interface Variant {
  id: string;
  name: string;
  sku: string;
  quantityAvailable: number;
  isAvailable: boolean;
  pricing: {
    onSale: boolean;
    priceUndiscounted: TaxedMoney;
    price: TaxedMoney;
  };
  attributes: VariantAttribute[];
  product: Product;
  images?: { url: string }[];
}

export interface CheckoutLine {
  id: string;
  quantity: number;
  totalPrice: TaxedMoney;
  variant: Variant;
  requiresShipping: boolean;
}

export interface StoreCheckout {
  id: string;
  token: string;
  totalPrice: TaxedMoney;
  subtotalPrice: TaxedMoney;
  shippingPrice: TaxedMoney;
  upfrontPrice: TaxedMoney;
  billingAddress: StoreAddress;
  shippingAddress: StoreAddress;
  email: string;
  isShippingRequired: boolean;
  availableShippingMethods: ShippingMethod[];
  availablePaymentGateways: StorePaymentGateway[];
  shippingMethod: ShippingMethod;
  lines: CheckoutLine[];
  voucherCode: string;
  discountName: string;
  discount: Money;
}

export interface CreateCheckoutData {
  checkoutCreate: {
    checkout: StoreCheckout;
    checkoutErrors: CheckoutError[];
    created: boolean;
  };
}

const DELETE_CHECKOUT_LINE_FIELDS = gql`
  ${WebShopPrice}
  ${ProductVariant}
  ${CheckoutLine}
  ${WebshopUserAddress}
  ${WebShopShippingMethod}
  ${WebShopCheckout}
`;

const UPDATE_CHECKOUT_LINES_FIELDS = gql`
  ${WebShopPrice}
  ${ProductVariant}
  ${CheckoutLine}
  ${WebshopUserAddress}
  ${WebShopShippingMethod}
  ${WebShopCheckout}
`;

const UPDATE_CHECKOUT_SHIPPING_METHOD_FIELDS = gql`
  ${WebShopPrice}
  ${ProductVariant}
  ${CheckoutLine}
  ${WebshopUserAddress}
  ${WebShopShippingMethod}
  ${WebShopCheckout}
`;

const UPDATE_CHECKOUT_SHIPPING_ADDRESS_FIELDS = gql`
  ${WebShopPrice}
  ${ProductVariant}
  ${CheckoutLine}
  ${WebshopUserAddress}
  ${WebShopShippingMethod}
  ${WebShopCheckout}
`;

const CHECKOUT_LINES_ADD_FIELDS = gql`
  ${WebShopPrice}
  ${WebShopCheckout}
  ${WebshopUserAddress}
  ${WebShopShippingMethod}
  ${CheckoutLine}
  ${ProductVariant}
`;
export const DELETE_CHECKOUT_LINE_MUTATION = gql`
  ${DELETE_CHECKOUT_LINE_FIELDS}
  mutation CheckoutLineDeleteMethod($checkoutId: ID!, $lineId: ID!) {
    checkoutLineDelete(checkoutId: $checkoutId, lineId: $lineId) {
      errors {
        field
        message
        __typename
      }
      checkout {
        ...WebShopCheckout
        __typename
      }
      checkoutErrors {
        field
        message
        code
        __typename
      }
      __typename
    }
  }
`;
export const UPDATE_CHECKOUT_LINES_MUTATION = gql`
  ${UPDATE_CHECKOUT_LINES_FIELDS}
  mutation UpdateCheckoutLinesMethod($checkoutId: ID!, $lines: [CheckoutLineInput]!) {
    checkoutLinesUpdate(checkoutId: $checkoutId, lines: $lines) {
      errors {
        field
        message
        __typename
      }
      checkout {
        ...WebShopCheckout
        __typename
      }
      checkoutErrors {
        field
        message
        code
        __typename
      }
      __typename
    }
  }
`;

export const UPDATE_CHECKOUT_SHIPPING_METHOD_MUTATION = gql`
  ${UPDATE_CHECKOUT_SHIPPING_METHOD_FIELDS}
  mutation UpdateCheckoutShippingMethod($checkoutId: ID!, $shippingMethodId: ID!) {
    checkoutShippingMethodUpdate(checkoutId: $checkoutId, shippingMethodId: $shippingMethodId) {
      errors {
        field
        message
        __typename
      }
      checkout {
        ...WebShopCheckout
        __typename
      }
      checkoutErrors {
        field
        message
        code
        __typename
      }
      __typename
    }
  }
`;

export const UPDATE_CHECKOUT_SHIPPING_ADDRESS_MUTATION = gql`
  ${UPDATE_CHECKOUT_SHIPPING_ADDRESS_FIELDS}
  mutation UpdateCheckoutShippingAddress($checkoutId: ID!, $shippingAddress: AddressInput!) {
    checkoutShippingAddressUpdate(checkoutId: $checkoutId, shippingAddress: $shippingAddress) {
      errors {
        field
        message
        __typename
      }
      checkout {
        ...WebShopCheckout
        __typename
      }
      checkoutErrors {
        field
        message
        code
        __typename
      }
      __typename
    }
  }
`;

export const CHECKOUT_LINES_ADD_MUTATION = gql`
  ${CHECKOUT_LINES_ADD_FIELDS}
  mutation CheckoutLinesAdd($checkoutId: ID!, $lines: [CheckoutLineInput]!) {
    checkoutLinesAdd(checkoutId: $checkoutId, lines: $lines) {
      errors {
        field
        message
        __typename
      }
      checkout {
        ...WebShopCheckout
        __typename
      }
      checkoutErrors {
        field
        message
        code
        __typename
      }
      __typename
    }
  }
`;
export interface UpdateCheckoutLinesVars {
  checkoutId: string;
  lines: [CheckoutLineInput];
}

export interface UpdateCheckoutLinesData {
  checkoutLinesUpdate: {
    errors: CheckoutError[];
    checkoutErrors: CheckoutError[];
    checkout: StoreCheckout;
  };
}

export interface DeleteCheckoutLineVars {
  checkoutId: string;
  lineId: string;
}

export interface DeleteCheckoutLineData {
  checkoutLineDelete: {
    errors: CheckoutError[];
    checkoutErrors: CheckoutError[];
    checkout: StoreCheckout;
  };
}

export interface UpdateCheckoutShippingMethodVars {
  checkoutId: string;
  shippingMethodId: string;
}

export interface UpdateCheckoutShippingMethodData {
  checkoutShippingMethodUpdate: {
    errors: CheckoutError[];
    checkoutErrors: CheckoutError[];
    checkout: StoreCheckout;
  };
}

export interface UpdateCheckoutShippingAddressVars {
  checkoutId: string;
  shippingAddress: AddressInput;
}

export interface CheckoutLinesAddData {
  checkoutLinesAdd: {
    errors: CheckoutError[];
    checkoutErrors: CheckoutError[];
    checkout: StoreCheckout;
  };
}

interface CheckoutLineInput {
  quantity: number;
  variantId: string;
}

export interface CheckoutLinesAddVars {
  checkoutId: string;
  lines: [CheckoutLineInput];
}

export interface UpdateCheckoutShippingAddressData {
  checkoutShippingAddressUpdate: {
    errors: CheckoutError[];
    checkoutErrors: CheckoutError[];
    checkout: StoreCheckout;
  };
}

export enum PaymentGatewayType {
  "Installments" = "bits.payments.v2",
  "Debit" = "bits.payments.v2.debit",
}

const CREATE_CHECKOUT_PAYMENT_FIELDS = gql`
  ${WebShopPrice}
  ${ProductVariant}
  ${CheckoutLine}
  ${WebshopUserAddress}
  ${WebShopShippingMethod}
  ${WebShopCheckout}
  fragment WebShopPayment on Payment {
    id
    gateway
    token
    creditCard {
      brand
      firstDigits
      lastDigits
      expMonth
      expYear
      __typename
    }
    __typename
  }
`;

export const CREATE_CHECKOUT_PAYMENT_MUTATION = gql`
  ${CREATE_CHECKOUT_PAYMENT_FIELDS}
  mutation CreateCheckoutPayment($checkoutId: ID!, $paymentInput: PaymentInput!) {
    checkoutPaymentCreate(checkoutId: $checkoutId, input: $paymentInput) {
      checkout {
        ...WebShopCheckout
        __typename
      }
      payment {
        ...WebShopPayment
        __typename
      }
      paymentErrors {
        field
        message
        code
        __typename
      }
      __typename
    }
  }
`;

export interface CreateCheckoutPaymentVars {
  checkoutId?: string;
  paymentInput: {
    gateway?: string;
    token?: string;
    amount?: number;
    billingAddress?: AddressInput;
  };
}

export interface StorePayment {
  id: string;
  gateway: string;
  token: string;
}

export interface PaymentError {
  field: string;
  message: string;
  code: number;
}

export interface CreateCheckoutPaymentData {
  checkoutPaymentCreate: {
    paymentErrors: PaymentError[];
    payment: StorePayment;
    checkout: StoreCheckout;
  };
}

export const CHECKOUT_COMPLETE_MUTATION = gql`
  mutation CheckoutComplete($checkoutId: ID!, $paymentData: JSONString, $redirectUrl: String!) {
    checkoutComplete(checkoutId: $checkoutId, paymentData: $paymentData, redirectUrl: $redirectUrl) {
      order {
        id
        status
      }
      confirmationData
      confirmationNeeded
      checkoutErrors {
        field
        message
      }
    }
  }
`;

export interface CheckoutCompleteVars {
  checkoutId?: string;
  paymentData: string;
  redirectUrl: string;
}

export interface StoreOrder {
  id: string;
  status: string;
}

export interface CheckoutCompleteData {
  checkoutComplete: {
    order: StoreOrder;
    confirmationData: string;
    checkoutErrors: CheckoutError[];
  };
}

export interface ConfirmationData {
  checkout_url: string;
  client_secret: string;
}

const ADD_PROMOCODE_FIELDS = gql`
  ${WebShopPrice}
  ${ProductVariant}
  ${CheckoutLine}
  ${WebshopUserAddress}
  ${WebShopShippingMethod}
  ${WebShopCheckout}
`;

export const ADD_PROMOCODE_MUTATION = gql`
  ${ADD_PROMOCODE_FIELDS}
  mutation CheckoutAddPromoCode($checkoutId: ID!, $promoCode: String!) {
    checkoutAddPromoCode(checkoutId: $checkoutId, promoCode: $promoCode) {
      errors {
        field
        message
        __typename
      }
      checkout {
        ...WebShopCheckout
        __typename
      }
      checkoutErrors {
        field
        message
        code
        __typename
      }
      __typename
    }
  }
`;

export interface AddPromocodeData {
  checkoutAddPromoCode: {
    errors: CheckoutError[];
    checkoutErrors: CheckoutError[];
    checkout: StoreCheckout;
  };
}

export interface AddPromocodeVars {
  checkoutId: string;
  promoCode: string;
}

export const REMOVE_PROMOCODE_MUTATION = gql`
  ${ADD_PROMOCODE_FIELDS}
  mutation CheckoutRemovePromoCode($checkoutId: ID!, $promoCode: String!) {
    checkoutRemovePromoCode(checkoutId: $checkoutId, promoCode: $promoCode) {
      errors {
        field
        message
        __typename
      }
      checkout {
        ...WebShopCheckout
        __typename
      }
      checkoutErrors {
        field
        message
        code
        __typename
      }
      __typename
    }
  }
`;

export interface RemovePromocodeData {
  checkoutRemovePromoCode: {
    errors: CheckoutError[];
    checkoutErrors: CheckoutError[];
    checkout: StoreCheckout;
  };
}

export interface RemovePromocodeVars {
  checkoutId: string;
  promoCode: string;
}
