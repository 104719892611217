import { ContentfulClientApi, createClient } from "contentful";
import { getCurrencySymbol } from "./country";

export interface Coupon {
  id: string;
  discountPercent: number;
}
export interface IPlanDetails {
  name?: string;
  creditLimit?: string;
  descriptions?: string[];
}

export interface ContentfulSubscriptionPlans {
  title: string;
  benefits: string[];
  description: string[];
  allowShowingPlanIds: number[];
  planDetails?: string[];
  coupons?: Coupon[];
  customPlanDetails?: IPlanDetails[];
}

export interface ContentfulSignupPayment {
  headerText: string;
  subcontentText: string;
}

export type ContentfulFeatureFlags = Record<string, boolean>;

export interface ContentfulSignupPage {
  title: string;
  subtitle: string;
  aggregator?: ContentfulAggregatorSignupPage;
}

export interface ContentfulAggregatorSignupPage {
  title?: string;
  subtitle?: string;
}
export interface ContentfulConfiguration {
  title?: string;
  subscriptionPlans: ContentfulSubscriptionPlans; // For Signup - Subscription Plan Page
  signupPayment: ContentfulSignupPayment; // Signup - Initial Checkout page
  featureFlags?: ContentfulFeatureFlags;
  dailyTips: string[]; // For Bits Daily Tips
  isFaisalAvailable: boolean | undefined; //For faisal availability feature on sidebar
  signup?: ContentfulSignupPage;
  displayImportantInformationPage: boolean | undefined; //Display important information page on the signup flow
  bitsTurbo: {
    customers: string[];
  };
}

export const defaultContentfulConfiguration: ContentfulConfiguration = {
  subscriptionPlans: {
    title: "",
    benefits: [],
    description: [],
    allowShowingPlanIds: [],
  },
  signupPayment: {
    headerText: "",
    subcontentText: "",
  },
  dailyTips: [],
  isFaisalAvailable: undefined,
  displayImportantInformationPage: undefined,
  bitsTurbo: {
    customers: [],
  },
};

export const replaceContentfulSubscriptionPrice = (
  contentfulText: string,
  currencyText: string,
  subscriptionPrice: number,
  interval: string
) => {
  return contentfulText
    .replace(/%SUBSCRIPTION_PRICE%/g, `${getCurrencySymbol(currencyText)}${subscriptionPrice}`)
    .replace(/%SUBSCRIPTION_INTERVAL%/g, interval);
};

const getContentfulClient = (): ContentfulClientApi | undefined => {
  const contentfulSpace = process.env.REACT_APP_CONTENTFUL_SPACE;
  const contentfulToken = process.env.REACT_APP_CONTENTFUL_TOKEN;
  if (
    contentfulSpace === undefined ||
    contentfulSpace === "" ||
    contentfulToken === undefined ||
    contentfulToken === ""
  ) {
    return undefined;
  }
  return createClient({
    space: contentfulSpace,
    accessToken: contentfulToken,
  });
};

export class ContentfulClient {
  private static apiClient: ContentfulClientApi | undefined;
  private static mainContent: ContentfulConfiguration | undefined;
  private static instance: ContentfulClient;

  private constructor() {}

  public static getInstance = async (): Promise<ContentfulClient> => {
    if (!ContentfulClient.instance) {
      ContentfulClient.instance = new ContentfulClient();
      try {
        this.apiClient = getContentfulClient();
        const contentfulEntry = process.env.REACT_APP_CONTENTFUL_ENTRY;
        if (this.apiClient === undefined || contentfulEntry === undefined || contentfulEntry === "") {
          this.mainContent = undefined;
        } else {
          const config = await this.apiClient?.getEntry<ContentfulConfiguration>(contentfulEntry);
          this.mainContent = config.fields;
        }
      } catch (e) {
        this.mainContent = undefined;
      }
    }
    return ContentfulClient.instance;
  };

  public getContent = (): ContentfulConfiguration | undefined => {
    return ContentfulClient.mainContent;
  };
}
