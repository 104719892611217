import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Route, Routes, useNavigate } from "react-router-dom";
import SignUpContextProvider from "../../../../context/SignUpContext";
import { SubscriptionPlan } from "../../../signup/SignUpApi";
import { useStickyState } from "../../../../hooks/useStickyState";
import "../../../signup/SignUp.scss";
import { EAggregators } from "utils/aggregators";
import { SignupAggregator } from "./contact/SignupAggregator";
interface ISignUp {
  setHeaderClass: (headerClass: string | null) => void;
  setWrapperClass: (wrapperClass: string | null) => void;
  setBypassAuthCheck: (bypassAuthCheck: boolean) => void;
  setDisplayBackButton: (displayBackButton: boolean) => void;
  setAggregator: (aggregator: EAggregators | undefined) => void;
}

interface IUseSignUp {
  layout: string;
  setSignUpStep: (signUpStep: number) => void;
  isBeforeSubscriptionPlansPage: boolean;
}

export const SIGN_UP_V2_INDEX_ROUTE_STEP_NO = 0;
export const SIGN_UP_V2_PHONE_NUMBER_STEP_NO = 1;
export const SIGN_UP_V2_VERIFY_PHONE_NUMBER_STEP_NO = 2;
export const SIGN_UP_V2_CREATE_PASSWORD_STEP_NO = 3;
export const SIGN_UP_V2_SUBSCRIPTION_PLANS_STEP_NO = 4;
export const SIGN_UP_V2_LEGAL_NAME_STEP_NO = 5;
export const SIGN_UP_V2_REFERRALS_STEP_NO = 6;
export const SIGN_UP_V2_IMPORTANT_INFORMATION_STEP_NO = 8;
export const SIGN_UP_V2_ABOUT_YOUR_DATA_STEP_NO = 7;
export const LOADING_CREDIT_FILE_STEP_NO = 999;
export const SIGN_UP_V2_PAYMENT_STEP_NO = 9;
export const SIGN_UP_V2_CHANGE_PAYMENT_DATE_STEP_NO = 10;
export const SIGN_UP_V2_CONFIRMATION_STEP_NO = 11;
export const SIGN_UP_V2_SCHEDULE_CONFIRMATION_STEP_NO = 12;

export const SIGN_UP_V2_INDEX_ROUTE_URL = "/signup";
export const SIGN_UP_V2_PHONE_NUMBER_URL = "/signup/phone";
export const SIGN_UP_V2_VERIFY_PHONE_NUMBER_URL = "/signup/smsverification";
export const SIGN_UP_V2_LEGALNAME_URL = "/signup/legal";
export const SIGN_UP_V2_SUBSCRIPTION_PLANS_URL = "/signup/subscription";
export const SIGN_UP_V2_CREATE_PASSWORD_URL = "/signup/password";
export const SIGN_UP_V2_REFERRALS_URL = "/signup/referrals";
export const SIGN_UP_V2_IMPORTANT_INFORMATION_URL = "/signup/important-information";
export const SIGN_UP_V2_ABOUT_YOUR_DATA_URL = "/signup/aboutyourdata";
export const SIGN_UP_V2_PAYMENT_URL = "/signup/payment";
export const SIGN_UP_V2_CHANGE_PAYMENT_DATE_URL = "/signup/paymentdate";
export const SIGN_UP_V2_CONFIRMATION_URL = "/signup/confirmation";
export const SIGN_UP_V2_SCHEDULE_URL = "/signup/schedule";
export const SIGN_UP_V2_TOTALLY_MONEY_URL = "/signup/totallymoney";
export const SIGN_UP_V2_CREDIT_KARMA_URL = "/signup/creditkarma";
export const SIGN_UP_V2_CLEARSCORE_URL = "/signup/clearscore";

enum SignupPaths {
  EMAIL = "email",
  PHONE_NUMBER = "phone",
  ADDRESS = "address",
  CONTACT_INFO = "contactinfo",
  PASSWORD = "password",
  REFERRALS = "referrals",
  SCHEDULE = "schedule",
  IMPORTANT_INFORMATION = "important-information",
  ABOUT_YOUR_DATA = "aboutyourdata",
  SUBSCRIPTION = "subscription",
  PAYMENT = "payment",
  PAYMENT_DATE = "paymentdate",
  CONFIRMATION = "confirmation",
  LEGAL = "legal",
  PHONE_VERIFICATION = "smsverification",
  TOTALLY_MONEY = "totallymoney",
  CLEARSCORE = "clearscore",
  CREDIT_KARMA = "creditkarma",
}
/* eslint-disable @typescript-eslint/no-unused-vars */
const useSignUp = ({ setHeaderClass, setWrapperClass, setDisplayBackButton }: any): IUseSignUp => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [isBeforeSubscriptionPlansPage] = useState(false);
  const [, setSignUpStep] = useStickyState<number>(0, "signUpStep");
  const [styleClasses] = useState<any>({
    headerClass: "",
    wrapperClass: "",
    layout: "welcome-layout",
  });
  const [subscriptionPlan, setSubscriptionPlan] = useStickyState<SubscriptionPlan | undefined>(
    undefined,
    "subscriptionPlan"
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname.endsWith("/signup")) {
      return navigate("/login");
    }
  }, [window.location.pathname]);

  const layout: string = styleClasses?.layout || "welcome-layout";

  return {
    layout,
    setSignUpStep,
    isBeforeSubscriptionPlansPage,
  };
};

export const SignUpV2 = ({
  setHeaderClass,
  setWrapperClass,
  setBypassAuthCheck,
  setDisplayBackButton,
  setAggregator,
}: ISignUp) => {
  const { layout, setSignUpStep } = useSignUp({
    setHeaderClass,
    setWrapperClass,
    setDisplayBackButton,
  });

  return (
    <SignUpContextProvider>
      <div className={layout}>
        <Container className="pb-3">
          <Row className="justify-content-center">
            <Col>
              <Routes>
                <Route
                  path={SignupPaths.TOTALLY_MONEY}
                  element={
                    <SignupAggregator
                      aggregator={EAggregators.TOTALLY_MONEY_STORE_CARD}
                      setSignUpStep={setSignUpStep}
                      setAggregator={setAggregator}
                    />
                  }
                />
                <Route
                  path={SignupPaths.CLEARSCORE}
                  element={
                    <SignupAggregator
                      aggregator={EAggregators.CLEARSCORE_STORE_CARD}
                      setSignUpStep={setSignUpStep}
                      setAggregator={setAggregator}
                    />
                  }
                />
                <Route
                  path={SignupPaths.CREDIT_KARMA}
                  element={
                    <SignupAggregator
                      aggregator={EAggregators.CREDIT_KARMA_STORE_CARD}
                      setSignUpStep={setSignUpStep}
                      setAggregator={setAggregator}
                    />
                  }
                />
              </Routes>
            </Col>
          </Row>
        </Container>
      </div>
    </SignUpContextProvider>
  );
};
