import { useTranslation } from "react-i18next";
import { OrderSummaryLine } from "./OrderSummaryLine";
import { OrderSummaryHeading } from "./OrderSummaryHeading";
import { useEffect, useState } from "react";
import { useShoppingCartContext } from "../../context/ShoppingCartContext";
import { getCurrencyFromCountry } from "../../utils/country";
import ShoppingCart from "../checkout/cart/ShoppingCart";

const useOrderSummary = () => {
  const [subtotal, setSubtotal] = useState<number>(0);
  const [shipping, setShipping] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [currency] = useState<string>(getCurrencyFromCountry());
  const { shoppingCartCheckout, completedCartCheckout } = useShoppingCartContext();
  const { t } = useTranslation();
  useEffect(() => {
    const cart = shoppingCartCheckout ?? completedCartCheckout;
    setSubtotal(cart?.subtotalPrice?.gross?.amount ?? 0);
    setTotal(cart?.totalPrice?.gross?.amount ?? 0);
    setShipping(cart?.shippingPrice?.gross?.amount ?? 0);
    setDiscount(cart?.discount?.amount ?? 0);
  }, [shoppingCartCheckout, completedCartCheckout]);
  return {
    subtotal: `${currency}${subtotal.toFixed(2)}`,
    total: `${currency}${total.toFixed(2)}`,
    shipping: shipping === 0 ? t("checkout.free").toUpperCase() : `${currency}${shipping.toFixed(2)}`,
    checkout: shoppingCartCheckout,
    discount: `${currency}${discount.toFixed(2)}`,
    hasDiscount: shoppingCartCheckout?.voucherCode != null || completedCartCheckout?.voucherCode != null,
  };
};
export const OrderSummary = () => {
  const { t } = useTranslation();
  const { total, subtotal, shipping, checkout, discount, hasDiscount } = useOrderSummary();
  return (
    <>
      <OrderSummaryHeading title={t("cart.order.title")} />
      {checkout && (
        <div className={checkout?.lines.length > 2 ? "summary-container scroll" : "summary-container"}>
          <ShoppingCart checkout={checkout} showTotals={false} />
        </div>
      )}
      <OrderSummaryLine label={t("cart.order.subtotal")} value={subtotal} dataCy="order-summary-subtotal" />
      <OrderSummaryLine
        label={t("cart.order.shipping")}
        value={shipping}
        dataCy="order-summary-shipping"
        className={`${!hasDiscount ? "order-summary-line-shipping" : ""}`}
      />
      {hasDiscount && (
        <OrderSummaryLine
          label={t("cart.order.discount")}
          value={discount}
          dataCy="order-summary-discount"
          className="order-summary-line-shipping"
        />
      )}
      <OrderSummaryLine
        label={t("cart.order.total")}
        value={total}
        valueClassName="order-summary-line-total-value"
        dataCy="order-summary-total"
      />
    </>
  );
};
