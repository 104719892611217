import "../../SignUpFlow.scss";
import { EAggregators } from "utils/aggregators";
import { useEffect } from "react";

const aggregatorMap: any = {};
aggregatorMap[EAggregators.TOTALLY_MONEY_STORE_CARD] = "totallymoney";
aggregatorMap[EAggregators.CLEARSCORE_STORE_CARD] = "clearscore";
aggregatorMap[EAggregators.CREDIT_KARMA_STORE_CARD] = "creditkarma";

export const SignupAggregator = ({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  aggregator,
  setSignUpStep,
  setAggregator,
}: /* eslint-disable @typescript-eslint/no-unused-vars */
{
  aggregator: EAggregators;
  setSignUpStep: (stepNumber: any) => any;
  setAggregator: (setAggregator: EAggregators | undefined) => void;
}): JSX.Element | null => {
  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_WEB_LANDING_URL}/Card-home`;
  }, []);

  return <></>;
};
