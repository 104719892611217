import { getJson } from "utils/api";

export interface CreditCardSubscriptionInfo {
  balance: number;
  minPayment: number;
  lateFee: number;
  subscriptionFee: number;
  totalFee: number;
  totalPayoff: number;
  nextPaymentAmount: number;
  nextPaymentDate: string;
  isOverdue: boolean;
}

export interface CreditCardProfileResponse {
  user: {
    creditLimit: number;
  };
  cards: any[];
  activeCard: any;
  isDisabled: boolean;
  isActivated: boolean;
  isEligible: boolean;
}

export class CreditCardApi {
  async getSubscriptionInfo(): Promise<CreditCardSubscriptionInfo> {
    return await getJson("user/balance", process.env.REACT_APP_BASE_SPEEDBOAT_API_URL, true)
      .then((response) => response.json())
      .then((response) => response["data"]);
  }

  async getProfile(): Promise<CreditCardProfileResponse> {
    return await getJson("me/profile", process.env.REACT_APP_BASE_SPEEDBOAT_API_URL, true)
      .then((response) => response.json())
      .then((response) => response["data"]);
  }
}
