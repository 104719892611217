import { useNavigate, useLocation } from "react-router-dom";
import AuthService from "./AuthService";
import { useBitsUserContext } from "../../context/SignedInContext";
import { useEffect } from "react";
import { TripleSpinner } from "../ui/TripleSpinner";
import { isCountryUk } from "utils/country";

export function RequireAuth({ children }: { children: JSX.Element }) {
  const isUK = isCountryUk();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { wallet, loadWallet, loadUserInfo, loadPocket, loadKyc } = useBitsUserContext();

  useEffect(() => {
    loadWallet();
    loadUserInfo();
    loadPocket();
    loadKyc();
  }, []);

  const isAuthOptional = pathname.toLocaleLowerCase().startsWith("/store");

  useEffect(() => {
    if (!isAuthOptional) {
      if (!AuthService.isAuthenticated()) {
        navigate("/login", { state: { from: location } });
      }
    }
  }, [wallet]);

  if (!isAuthOptional && wallet == null) {
    return (
      <>
        {isUK ? (
          <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
            <TripleSpinner />
          </div>
        ) : (
          <TripleSpinner />
        )}
      </>
    );
  }

  return children;
}
