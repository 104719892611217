import { Extras } from "@sentry/types";
import { ApolloError, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  AccountError,
  AddressInput,
  CheckoutError,
  CheckoutLinesAddData,
  CheckoutLinesAddVars,
  CHECKOUT_LINES_ADD_MUTATION,
  CreateCheckoutData,
  CreateCheckoutPaymentData,
  CreateCheckoutPaymentVars,
  CreateCheckoutVars,
  CREATE_CHECKOUT_MUTATION,
  CREATE_CHECKOUT_PAYMENT_MUTATION,
  PaymentError,
  PaymentGatewayType,
  StoreAddress,
  StoreCheckout,
  StoreUser,
  TokenExchangeData,
  TokenExchangeVars,
  TOKEN_EXCHANGE_MUTATION,
  UpdateCheckoutShippingAddressData,
  UpdateCheckoutShippingAddressVars,
  UpdateCheckoutShippingMethodData,
  UpdateCheckoutShippingMethodVars,
  UPDATE_CHECKOUT_SHIPPING_ADDRESS_MUTATION,
  UPDATE_CHECKOUT_SHIPPING_METHOD_MUTATION,
  UPDATE_CHECKOUT_LINES_MUTATION,
  UpdateCheckoutLinesData,
  UpdateCheckoutLinesVars,
  CheckoutLine,
  DeleteCheckoutLineData,
  DeleteCheckoutLineVars,
  DELETE_CHECKOUT_LINE_MUTATION,
  ConfirmationData,
  CheckoutCompleteData,
  CheckoutCompleteVars,
  CHECKOUT_COMPLETE_MUTATION,
  ADD_PROMOCODE_MUTATION,
  AddPromocodeData,
  AddPromocodeVars,
  REMOVE_PROMOCODE_MUTATION,
  RemovePromocodeData,
  RemovePromocodeVars,
} from "../components/checkout/Checkout.types";
import { useShoppingCartContext } from "../context/ShoppingCartContext";
import logger, { LogTagKey } from "../utils/logger";
import AuthService from "../components/auth/AuthService";
import dayjs from "dayjs";
import { PaymentMethod, Pricing } from "../components/checkout/Checkout";
import { addMoney, subtractMoney } from "../utils/money";
import { useStickyState } from "./useStickyState";
import { makeCheckoutRedirectUrls } from "../components/checkout/CheckoutUtils";
import { getCurrencyFromCountry } from "../utils/country";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { useBitsUserContext } from "context/SignedInContext";
import { isSubscriptionActive } from "../components/payment/PaymentApi";
import { UserKycStatus } from "../components/score/KycApi";

const TOKEN_EXPIRATION_BUFFER = 120;

interface IUseCheckout {
  shoppingCartCheckout: StoreCheckout | undefined;
  setShoppingCartCheckout: (shoppingCartCheckout: StoreCheckout | undefined) => void;
  error: string | undefined;
  setStoreToken: (storeToken: string) => void;
  addItemToCart: (variant: string, quantity: number) => Promise<StoreCheckout | undefined>;
  addPromocode: (checkout?: StoreCheckout | undefined) => Promise<StoreCheckout | undefined>;
  storeToken: string | undefined;
  isCartLoading: boolean;
  storeUser: StoreUser | undefined;
  setStoreUser: (storeUser: StoreUser | undefined) => void;
  hasCartExpired: boolean;
  createPricing: (checkout: StoreCheckout | null, checkoutMode: PaymentMethod) => Pricing | null;
  createCheckoutPayment: (gateway: PaymentGatewayType, checkout?: StoreCheckout | undefined) => Promise<any>;
  createPayments: (checkout?: StoreCheckout | undefined) => void;
  debitPricing: Pricing | undefined;
  creditPricing: Pricing | undefined;
  updateCheckoutShippingAddress: (shippingAddress: AddressInput | undefined) => Promise<void>;
  updateLineQuantity: (line: CheckoutLine, quantity: number) => Promise<void>;
  removeLine: (lineId: string | undefined) => Promise<void>;
  confirmOrder: (waitOrder: boolean) => Promise<ConfirmationData | null>;
  confirmingOrder: number | undefined;
  isVelocityError: boolean;
  isAddressError: boolean;
  isCreditLimitError: boolean;
  setIsVelocityError: (v: boolean) => void;
  setIsAddressError: (v: boolean) => void;
  setIsCreditLimitError: (v: boolean) => void;
  kycRequired: boolean;
  kycDeclined: boolean;
}

export const useCheckout = (): IUseCheckout => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined>();
  const [storeToken, setStoreToken] = useStickyState<string>(undefined, "checkoutStoreToken");
  const [storeUser, setStoreUser] = useStickyState<StoreUser | undefined>(undefined, "checkoutStoreUser");
  const [isVelocityError, setIsVelocityError] = useState<boolean>(false);
  const [isAddressError, setIsAddressError] = useState<boolean>(false);
  const [isCreditLimitError, setIsCreditLimitError] = useState<boolean>(false);
  const [hasCartExpired, setHasCartExpired] = useState<boolean>(false);
  const {
    shoppingCartCheckout,
    setShoppingCartCheckout,
    cartExpirationDate,
    setCartExpirationDate,
    debitPricing,
    creditPricing,
    setDebitPricing,
    setCreditPricing,
  } = useShoppingCartContext();
  const { isPayInThree, wallet, kyc, loadKyc } = useBitsUserContext();
  const [confirmingOrder, setConfirmingOrder] = useStickyState<string | undefined>(
    undefined,
    "shopping-cart-confirming"
  );
  useEffect(() => {
    if (!shoppingCartCheckout || Object.keys(shoppingCartCheckout).length === 0) {
      return;
    }
    setShoppingCartCheckout(shoppingCartCheckout);
  }, [shoppingCartCheckout]);

  useEffect(() => {
    if (shoppingCartCheckout) {
      const hasCartExpired = cartExpirationDate ? cartExpirationDate < dayjs().unix() : false;
      setHasCartExpired(hasCartExpired);
    }
  }, []);

  useEffect(() => {
    // if wallet plan was changed, re-apply promocode
    if (
      wallet &&
      shoppingCartCheckout &&
      debitPricing &&
      shoppingCartCheckout.voucherCode &&
      shoppingCartCheckout.voucherCode !== wallet?.plan?.name
    ) {
      createPayments(shoppingCartCheckout);
    }
  }, [wallet, shoppingCartCheckout]);

  useEffect(() => {
    if (!kyc) {
      loadKyc();
    }
  }, [kyc, loadKyc]);

  const kycRequired = !kyc || ![UserKycStatus.kycApproved, UserKycStatus.kycDeclined].includes(kyc.kycStatus!);
  const kycDeclined = kyc?.kycStatus === UserKycStatus.kycDeclined;

  const createApolloAuthProps = (token?: string) => ({
    context: { headers: { Authorization: `JWT ${token ?? storeToken}` } },
  });

  const apolloAuthProps = createApolloAuthProps();

  const transformAddressToInput = (address: StoreAddress): AddressInput => ({
    firstName: address?.firstName,
    lastName: address?.lastName,
    companyName: address?.companyName,
    streetAddress1: address?.streetAddress1,
    streetAddress2: address?.streetAddress2,
    city: address?.city,
    cityArea: "",
    postalCode: address?.postalCode,
    country: address?.country?.code,
    countryArea: address?.countryArea,
    phone: address?.phone,
  });

  const checkIfVelocityError = (err: string) => {
    return err === "You have reached a limit for creating order";
  };

  const checkIfCreditLimitError = (err: string) => {
    return err.includes("reached credit limit");
  };

  const checkIfAddressError = (err: string) => {
    return err === "This value is not valid for the address.";
  };

  const checkIfCheckoutIdError = (err: string) => {
    return err.includes("resolve to a node");
  };

  const handleInternalError = (error: Error, errorExtras: Extras) => {
    logger.error(error, LogTagKey.TkCheckout, "Checkout - Internal error", errorExtras);
    setError(t("checkout.error.generic"));
    navigate("error");
  };

  const handleCheckoutError = (errors: (CheckoutError | PaymentError | AccountError)[]) => {
    const errorDetail = errors.map((err) => err.message ?? err.code).join(",");
    const extrasData: Extras = {};
    errors.forEach((err, index) => {
      extrasData[`error${index}`] = JSON.stringify(err, Object.getOwnPropertyNames(err));
    });
    setIsVelocityError(checkIfVelocityError(errorDetail));
    setIsAddressError(checkIfAddressError(errorDetail));
    setIsCreditLimitError(checkIfCreditLimitError(errorDetail));
    logger.error(new Error(errorDetail), LogTagKey.TkCheckout, "Checkout error", extrasData);
    setError(errorDetail);
    navigate("error", {
      state: {
        cleanup: checkIfCheckoutIdError(errorDetail),
      },
    });
  };

  const handleApolloError = (stage: string) => (err: ApolloError) => {
    logger.error(err, LogTagKey.TkCheckout, `Saleor mutation error during ${stage}`);
    setError(t("checkout.error.generic"));
    navigate("error");
  };

  const [removePromocodeFunction, { loading: loading11 }] = useMutation<RemovePromocodeData, RemovePromocodeVars>(
    REMOVE_PROMOCODE_MUTATION,
    {
      onError: handleApolloError("Checkout remove promocode"),
    }
  );

  const [addPromocodeFunction, { loading: loading10 }] = useMutation<AddPromocodeData, AddPromocodeVars>(
    ADD_PROMOCODE_MUTATION,
    {
      onError: handleApolloError("Checkout add promocode"),
    }
  );

  const [checkoutLinesAddFunction, { loading: loading7 }] = useMutation<CheckoutLinesAddData, CheckoutLinesAddVars>(
    CHECKOUT_LINES_ADD_MUTATION,
    {
      onError: handleApolloError("Checkout add lines"),
    }
  );

  const [updateCheckoutShippingMethodFunction, { loading: loading3 }] = useMutation<
    UpdateCheckoutShippingMethodData,
    UpdateCheckoutShippingMethodVars
  >(UPDATE_CHECKOUT_SHIPPING_METHOD_MUTATION, {
    onError: handleApolloError("update shipping method"),
  });

  const [updateCheckoutLinesFunction, { loading: loading8 }] = useMutation<
    UpdateCheckoutLinesData,
    UpdateCheckoutLinesVars
  >(UPDATE_CHECKOUT_LINES_MUTATION, {
    onError: handleApolloError("update checkout lines method"),
  });

  const [deleteCheckoutLineFunction, { loading: loading9 }] = useMutation<
    DeleteCheckoutLineData,
    DeleteCheckoutLineVars
  >(DELETE_CHECKOUT_LINE_MUTATION, {
    onError: handleApolloError("delete checkout line method"),
  });

  const [createCheckoutFunction, { loading: loading2 }] = useMutation<CreateCheckoutData, CreateCheckoutVars>(
    CREATE_CHECKOUT_MUTATION,
    {
      onError: handleApolloError("create checkout"),
    }
  );

  const [checkoutCompleteFunction, { loading: loading5 }] = useMutation<CheckoutCompleteData, CheckoutCompleteVars>(
    CHECKOUT_COMPLETE_MUTATION,
    {
      onError: handleApolloError("checkout complete"),
    }
  );

  const [tokenExchangeFunction, { loading: loading1 }] = useMutation<TokenExchangeData, TokenExchangeVars>(
    TOKEN_EXCHANGE_MUTATION,
    {
      onError: handleApolloError("token exchange"),
    }
  );

  const tokenExchange = async (): Promise<{ token: string; user: StoreUser }> => {
    if (storeUser && storeToken) {
      try {
        const decoded = jwtDecode<JwtPayload>(storeToken);
        if (decoded?.exp && decoded.exp - Date.now() / 1000 > TOKEN_EXPIRATION_BUFFER) {
          return {
            token: storeToken,
            user: storeUser,
          };
        }
      } catch (e: any) {
        logger.warn("Error decoding Saleor token", LogTagKey.TkCheckout, "Error decoding Saleor token");
      }
    }
    return new Promise((resolve: any, reject: any) => {
      tokenExchangeFunction({
        variables: { token: AuthService.getAuthToken() },
        onCompleted: async ({ tokenExchange: { token, user, accountErrors } }) => {
          setStoreUser(undefined);
          if (accountErrors?.length > 0) {
            handleCheckoutError(accountErrors);
            return reject(accountErrors);
          }
          if (token != null) {
            if (accountErrors?.length > 0) {
              handleCheckoutError(accountErrors);
              return reject(accountErrors);
            }

            setStoreToken(token);
            setStoreUser(user);

            // we have started checkout so we are back from Stripe
            if (!shoppingCartCheckout) {
              setShoppingCartCheckout(user.checkout ?? undefined);
            }
            return resolve({ token, user });
          }
        },
      });
    });
  };

  const createCheckout = async (
    variant: string,
    quantity: number,
    email: string,
    defaultShippingAddress: StoreAddress,
    defaultBillingAddress: StoreAddress,
    token: string
  ): Promise<StoreCheckout | undefined> => {
    if (!shoppingCartCheckout || Object.keys(shoppingCartCheckout)?.length === 0) {
      const createCheckoutDataFetchResult = await createCheckoutFunction({
        variables: {
          quantity,
          variantId: variant!,
          email: email,
          shippingAddress: transformAddressToInput(defaultShippingAddress),
          billingAddress: transformAddressToInput(defaultBillingAddress),
          forceNew: true, // we always cleanup the existing checkout session when coming from the Store
        },
        ...createApolloAuthProps(token),
      });

      if (createCheckoutDataFetchResult.data?.checkoutCreate?.checkoutErrors?.length) {
        handleCheckoutError(createCheckoutDataFetchResult.data?.checkoutCreate?.checkoutErrors);
        return;
      }

      return createCheckoutDataFetchResult?.data?.checkoutCreate?.checkout;
    }
    return shoppingCartCheckout;
  };

  const hasAvailableShippingMethods = (checkout: StoreCheckout | undefined): boolean => {
    if (!checkout) {
      return false;
    }
    if (checkout?.isShippingRequired && checkout?.availableShippingMethods?.length === 0) {
      setError(t("checkout.error.missingShippingMethod"));
      logger.error(
        new Error("Missing shipping method"),
        LogTagKey.TkCheckout,
        "Checkout error without navigating to error page",
        {
          isShippingRequired: checkout?.isShippingRequired,
          availableShippingMethodsLength: checkout?.availableShippingMethods?.length,
        }
      );
      return false;
    }
    return true;
  };

  const updateCheckoutShippingMethod = async (
    checkout: StoreCheckout | undefined,
    token: string
  ): Promise<StoreCheckout | undefined> => {
    if (checkout?.isShippingRequired) {
      const updateCheckoutShippingMethodResult = await updateCheckoutShippingMethodFunction({
        variables: {
          checkoutId: checkout.id,
          shippingMethodId: checkout.availableShippingMethods[0].id,
        },
        ...createApolloAuthProps(token),
      });

      if (
        updateCheckoutShippingMethodResult?.data?.checkoutShippingMethodUpdate?.errors?.length ||
        updateCheckoutShippingMethodResult?.data?.checkoutShippingMethodUpdate?.checkoutErrors?.length
      ) {
        handleCheckoutError([
          ...updateCheckoutShippingMethodResult?.data?.checkoutShippingMethodUpdate?.errors,
          ...updateCheckoutShippingMethodResult?.data?.checkoutShippingMethodUpdate?.checkoutErrors,
        ]);
        return undefined;
      }
      const checkoutFromResult = updateCheckoutShippingMethodResult.data?.checkoutShippingMethodUpdate?.checkout;

      if (checkoutFromResult == null) {
        logger.error(
          new Error("Update checkout shipping method failed"),
          LogTagKey.TkCheckout,
          "Checkout error without navigating to error page"
        );
        return undefined;
      }
      setShoppingCartCheckout(checkoutFromResult);
      return checkoutFromResult;
    } else {
      setShoppingCartCheckout(checkout);
    }
    return checkout;
  };

  const removePromocode = async (
    checkout: StoreCheckout | undefined = shoppingCartCheckout
  ): Promise<StoreCheckout | undefined> => {
    try {
      if (!checkout?.id) {
        return;
      }
      if (!checkout.voucherCode) {
        console.debug(`[useCheckout] removePromocode: Checkout does not have a discount applied`);
        return;
      }

      const { token } = await tokenExchange();
      const checkoutRemovePromocodeDataResult = await removePromocodeFunction({
        variables: {
          checkoutId: checkout!.id,
          promoCode: checkout.voucherCode,
        },
        ...createApolloAuthProps(token),
      });

      if (checkoutRemovePromocodeDataResult == null) {
        return;
      }

      if (
        checkoutRemovePromocodeDataResult.data?.checkoutRemovePromoCode.checkoutErrors.length ||
        checkoutRemovePromocodeDataResult.data?.checkoutRemovePromoCode.errors.length
      ) {
        const errorDetail = ([] as CheckoutError[])
          .concat(
            checkoutRemovePromocodeDataResult.data?.checkoutRemovePromoCode.checkoutErrors ?? ([] as CheckoutError[]),
            checkoutRemovePromocodeDataResult.data?.checkoutRemovePromoCode.errors ?? ([] as CheckoutError[])
          )
          .map((err: any) => err.message ?? err.code)
          .join(",");
        console.log(`[useCheckout] removePromocode: Error: ${errorDetail}`);
        return;
      }

      return checkoutRemovePromocodeDataResult.data?.checkoutRemovePromoCode.checkout;
    } catch (err: unknown) {
      console.error(`[useCheckout] removePromocode: Error: ${err}`);
      return;
    }
  };

  const addPromocode = async (
    checkout: StoreCheckout | undefined = shoppingCartCheckout
  ): Promise<StoreCheckout | undefined> => {
    try {
      if (!isSubscriptionActive(wallet) || !wallet?.plan?.name) {
        console.info(`[useCheckout] addPromocode: Wallet is not active or plan is not set`);
        return;
      }
      if (!checkout?.id) {
        return;
      }
      if (checkout.voucherCode && checkout.voucherCode === wallet?.plan?.name) {
        console.debug(`[useCheckout] addPromocode: Checkout already has a discount applied`);
        return;
      }

      const { token } = await tokenExchange();
      const checkoutAddPromocodeDataResult = await addPromocodeFunction({
        variables: {
          checkoutId: checkout!.id,
          promoCode: wallet?.plan?.name,
        },
        ...createApolloAuthProps(token),
      });

      if (checkoutAddPromocodeDataResult == null) {
        return;
      }

      if (
        checkoutAddPromocodeDataResult.data?.checkoutAddPromoCode.checkoutErrors.length ||
        checkoutAddPromocodeDataResult.data?.checkoutAddPromoCode.errors.length
      ) {
        const errorDetail = ([] as CheckoutError[])
          .concat(
            checkoutAddPromocodeDataResult.data?.checkoutAddPromoCode.checkoutErrors ?? ([] as CheckoutError[]),
            checkoutAddPromocodeDataResult.data?.checkoutAddPromoCode.errors ?? ([] as CheckoutError[])
          )
          .map((err: any) => err.message ?? err.code)
          .join(",");
        console.log(`[useCheckout] addPromocode: Error: ${errorDetail}`);
        return;
      }

      return checkoutAddPromocodeDataResult.data?.checkoutAddPromoCode.checkout;
    } catch (err: unknown) {
      console.error(`[useCheckout] addPromocode: Error: ${err}`);
      return;
    }
  };

  const addItemToCart = async (variant: string, quantity = 1): Promise<StoreCheckout | undefined> => {
    try {
      const { user, token } = await tokenExchange();
      const { email, defaultBillingAddress, defaultShippingAddress } = user;
      let checkout: StoreCheckout | undefined;
      if (!shoppingCartCheckout?.id || Object.keys(shoppingCartCheckout)?.length === 0) {
        checkout = await createCheckout(variant, quantity, email, defaultShippingAddress, defaultBillingAddress, token);
        if (checkout == null) {
          logger.error(
            new Error("Create checkout failed"),
            LogTagKey.TkCheckout,
            "Checkout error without navigating to error page"
          );
          return;
        }

        if (!hasAvailableShippingMethods(checkout)) {
          return;
        }

        checkout = await updateCheckoutShippingMethod(checkout, token);
        if (!checkout) {
          return;
        }
      } else {
        const checkoutLinesAddResult = await checkoutLinesAddFunction({
          variables: {
            checkoutId: shoppingCartCheckout.id,
            lines: [
              {
                variantId: variant,
                quantity,
              },
            ],
          },
          ...createApolloAuthProps(token),
        });

        const { errors, checkoutErrors } = checkoutLinesAddResult?.data?.checkoutLinesAdd || {};
        if (errors?.length || checkoutErrors?.length) {
          handleCheckoutError([...errors!, ...checkoutErrors!]);
          return;
        }

        checkout = checkoutLinesAddResult.data?.checkoutLinesAdd?.checkout;
        if (checkout == null) {
          logger.error(
            new Error("Checkout add line failed"),
            LogTagKey.TkCheckout,
            "Checkout error without navigating to error page"
          );
          return;
        }
        if (!hasAvailableShippingMethods(checkout)) {
          return;
        }

        checkout = await updateCheckoutShippingMethod(checkout, token);
        if (!checkout) {
          return;
        }
      }
      setCartExpirationDate(dayjs().add(2, "weeks").unix());
      await createPayments(checkout);
    } catch (err: unknown) {
      console.error(err);
      return;
    }
  };

  const createPayments = async (checkout: StoreCheckout | undefined = shoppingCartCheckout) => {
    const promoCheckout = await addPromocode(checkout);
    const res1 = await createCheckoutPayment(PaymentGatewayType.Installments, promoCheckout ?? checkout);
    const creditPricing = createPricing(res1?.checkout, PaymentMethod.Credit) ?? undefined;
    setCreditPricing(creditPricing);
    const res2 = await createCheckoutPayment(PaymentGatewayType.Debit, res1.checkout ?? promoCheckout ?? checkout);
    const debitPricing = createPricing(res2?.checkout, PaymentMethod.Credit) ?? undefined;
    setDebitPricing(debitPricing);
    if (res2?.checkout) setShoppingCartCheckout(res2.checkout);
  };

  const createPricing = (checkout: StoreCheckout | null, checkoutMode: PaymentMethod): Pricing | null => {
    const currency = checkout?.upfrontPrice?.gross?.currency ?? getCurrencyFromCountry();
    if (checkoutMode === PaymentMethod.Credit) {
      return {
        debit: {
          currency,
          amount: addMoney(checkout?.upfrontPrice?.gross, checkout?.shippingPrice?.gross)?.amount ?? 0,
        },
        credit: {
          currency,
          amount: subtractMoney(checkout?.subtotalPrice?.gross, checkout?.upfrontPrice?.gross)?.amount ?? 0,
        },
      };
    }
    if (checkoutMode === PaymentMethod.Debit) {
      return { debit: checkout?.totalPrice?.gross ?? { currency, amount: 0 } };
    } else {
      throw new Error("unknown checkout mode");
    }
  };

  const [createCheckoutPaymentFunction, { loading: loading4 }] = useMutation<
    CreateCheckoutPaymentData,
    CreateCheckoutPaymentVars
  >(CREATE_CHECKOUT_PAYMENT_MUTATION, {
    onError: handleApolloError("create payment"),
  });

  const createCheckoutPayment = async (
    gateway: PaymentGatewayType,
    checkout: StoreCheckout | undefined = shoppingCartCheckout
  ): Promise<any> => {
    if (!checkout?.id) {
      return null;
    }
    const { token } = await tokenExchange();
    const checkoutPaymentDataFetchResult = await createCheckoutPaymentFunction({
      variables: {
        checkoutId: checkout?.id,
        paymentInput: {
          gateway,
          billingAddress: transformAddressToInput(checkout?.billingAddress ?? ({} as any)), // making Typescript happy
          amount: checkout?.totalPrice?.gross?.amount,
          token,
        },
      },
      ...createApolloAuthProps(token),
    });

    if (checkoutPaymentDataFetchResult == null) {
      return null;
    }

    if (checkoutPaymentDataFetchResult.data?.checkoutPaymentCreate?.paymentErrors?.length) {
      handleCheckoutError(checkoutPaymentDataFetchResult.data?.checkoutPaymentCreate?.paymentErrors);
      return null;
    }

    return {
      checkout: checkoutPaymentDataFetchResult.data?.checkoutPaymentCreate?.checkout ?? null,
      payment: checkoutPaymentDataFetchResult.data?.checkoutPaymentCreate?.payment ?? null,
    };
  };

  const [updateCheckoutShippingAddressFunction, { loading: loading6 }] = useMutation<
    UpdateCheckoutShippingAddressData,
    UpdateCheckoutShippingAddressVars
  >(UPDATE_CHECKOUT_SHIPPING_ADDRESS_MUTATION, {
    onError: handleApolloError("update shipping address"),
  });

  const updateCheckoutShippingAddress = async (shippingAddress: AddressInput | undefined) => {
    try {
      if (!shoppingCartCheckout?.id || !shippingAddress) {
        return;
      }
      const updateCheckoutShippingAddressResult = await updateCheckoutShippingAddressFunction({
        variables: {
          checkoutId: shoppingCartCheckout.id,
          shippingAddress,
        },
        ...apolloAuthProps,
      });
      const { errors, checkoutErrors } = updateCheckoutShippingAddressResult?.data?.checkoutShippingAddressUpdate || {};
      if (errors?.length || checkoutErrors?.length) {
        handleCheckoutError([...errors!, ...checkoutErrors!]);
        return;
      }
      setShoppingCartCheckout(updateCheckoutShippingAddressResult.data?.checkoutShippingAddressUpdate?.checkout);
    } catch (err: unknown) {
      handleInternalError(new Error("Error changing delivery address"), {
        checkoutId: shoppingCartCheckout?.id,
        shippingAddress,
      });
    }
  };

  const updateLineQuantity = async (line: CheckoutLine, quantity: number): Promise<void> => {
    try {
      if (!shoppingCartCheckout?.id || !line) {
        return;
      }
      const { token } = await tokenExchange();
      const updateCheckoutLinesResult = await updateCheckoutLinesFunction({
        variables: {
          checkoutId: shoppingCartCheckout.id,
          lines: [
            {
              variantId: line.variant.id,
              quantity,
            },
          ],
        },
        ...createApolloAuthProps(token),
      });
      const { errors, checkoutErrors } = updateCheckoutLinesResult?.data?.checkoutLinesUpdate || {};
      if (errors?.length || checkoutErrors?.length) {
        handleCheckoutError([...errors!, ...checkoutErrors!]);
        return;
      }
      const { checkout } = updateCheckoutLinesResult.data?.checkoutLinesUpdate ?? {};
      setShoppingCartCheckout(checkout);
      await createPayments(checkout);
    } catch (err: unknown) {
      handleInternalError(new Error("Error updating checkout line"), {
        checkoutId: shoppingCartCheckout?.id,
        line,
      });
    }
  };

  const removeLine = async (lineId: string | undefined): Promise<void> => {
    try {
      if (!shoppingCartCheckout?.id || !lineId) {
        return;
      }
      const { token } = await tokenExchange();
      const deleteCheckoutLineResult = await deleteCheckoutLineFunction({
        variables: {
          checkoutId: shoppingCartCheckout.id,
          lineId,
        },
        ...createApolloAuthProps(token),
      });
      const { errors, checkoutErrors } = deleteCheckoutLineResult?.data?.checkoutLineDelete || {};

      if (errors?.length || checkoutErrors?.length) {
        handleCheckoutError([...errors!, ...checkoutErrors!]);
        return;
      }
      const { checkout } = deleteCheckoutLineResult.data?.checkoutLineDelete ?? {};
      setShoppingCartCheckout(checkout);
      await createPayments(checkout);
    } catch (err: unknown) {
      handleInternalError(new Error("Error updating checkout line"), {
        checkoutId: shoppingCartCheckout?.id,
        lineId,
      });
    }
  };

  const getCompleteOrderApolloAuthProps = async () => {
    const { token } = await tokenExchange();
    return createApolloAuthProps(token);
  };

  const confirmOrder = async (waitOrder: boolean): Promise<ConfirmationData | null> => {
    if (!shoppingCartCheckout?.id) {
      return null;
    }
    setConfirmingOrder(dayjs().toISOString());
    let checkoutCompleteResult: any = null;
    try {
      const variables = {
        checkoutId: shoppingCartCheckout?.id,
        paymentData: JSON.stringify(makeCheckoutRedirectUrls(isPayInThree)),
        redirectUrl: window.location.href.toString(),
        isPayInThree,
      };

      const apolloAuthProps = await getCompleteOrderApolloAuthProps();
      const checkoutCompleteDataFetchResult = await checkoutCompleteFunction({
        variables,
        ...apolloAuthProps,
      });

      checkoutCompleteResult = checkoutCompleteDataFetchResult.data?.checkoutComplete;
      if (checkoutCompleteResult?.checkoutErrors?.length) {
        if (checkoutCompleteResult?.checkoutErrors.find((err: any) => err.message === "Voucher not applicable")) {
          const checkout = await removePromocode(shoppingCartCheckout);
          if (checkout) {
            setShoppingCartCheckout(checkout);
            await createPayments(checkout ?? shoppingCartCheckout);
          }
          return confirmOrder(waitOrder);
        }
        if (
          checkoutCompleteResult?.checkoutErrors.find(
            (err: any) => err.message === "Provided payment methods can not cover the checkout's total amount"
          )
        ) {
          await createPayments(shoppingCartCheckout);
        }
        setConfirmingOrder(undefined);
        handleCheckoutError(checkoutCompleteResult?.checkoutErrors);
        throw new Error("checkout errors");
      }

      if (waitOrder && checkoutCompleteResult?.order == null) {
        return null;
      }

      if (checkoutCompleteResult?.confirmationData == null) {
        if (checkoutCompleteResult?.checkoutErrors?.length) {
          setConfirmingOrder(undefined);
          handleCheckoutError(checkoutCompleteResult?.checkoutErrors);
          throw new Error("checkout errors");
        }

        return null;
      }
      setConfirmingOrder(undefined);

      return JSON.parse(checkoutCompleteResult?.confirmationData);
    } catch (e) {
      setConfirmingOrder(false);
      handleInternalError(e as Error, { data: checkoutCompleteResult?.confirmationData });
      throw new Error("checkout errors");
    }
  };

  return {
    shoppingCartCheckout,
    setShoppingCartCheckout,
    error,
    setStoreToken,
    addItemToCart,
    addPromocode,
    storeToken,
    isCartLoading:
      loading1 ||
      loading2 ||
      loading3 ||
      loading4 ||
      loading5 ||
      loading7 ||
      loading6 ||
      loading8 ||
      loading9 ||
      loading10 ||
      loading11,
    storeUser,
    setStoreUser,
    hasCartExpired,
    createPricing,
    createCheckoutPayment,
    createPayments,
    debitPricing,
    creditPricing,
    updateCheckoutShippingAddress,
    updateLineQuantity,
    removeLine,
    confirmOrder,
    confirmingOrder,
    isVelocityError,
    isAddressError,
    isCreditLimitError,
    setIsVelocityError,
    setIsAddressError,
    setIsCreditLimitError,
    kycRequired,
    kycDeclined,
  };
};
