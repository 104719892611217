import { createContext, useContext } from "react";
import { AuthApi } from "../components/auth/AuthApi";
import { SignUpApi } from "../components/signup/SignUpApi";
import { PaymentApi } from "../components/payment/PaymentApi";
import { SubscriptionApi } from "../components/payment/SubscriptionApi";
import { PostalAddressApi } from "../components/settings/postalAddress/PostalAddressApi";
import { TagsApi } from "../components/auth/TagsApi";
import { PeriodicStatementsApi } from "../components/periodicStatements/PeriodicStatementsApi";
import { ScoreApi } from "../components/score/ScoreApi";
import { KycApi } from "../components/score/KycApi";
import { CreditCardApi } from "components/cassetteUI/store/subscription-info/credit-card/CreditCardApi";

const ApiContext = createContext({
  auth: new AuthApi(),
  signUp: new SignUpApi(),
  payment: new PaymentApi(),
  subscription: new SubscriptionApi(),
  address: new PostalAddressApi(),
  tags: new TagsApi(),
  periodicStatements: new PeriodicStatementsApi(),
  score: new ScoreApi(),
  kyc: new KycApi(),
  creditCard: new CreditCardApi(),
});

export default function useApi() {
  return useContext(ApiContext);
}
