import useApi from "./useApi";
import { buildBitsUniversalLink } from "../utils/aggregators";
import { v4 as uuid4 } from "uuid";
import { mixPanelInstance } from "../utils/analytics/mixpanel";

export const useAggregatorTracking = () => {
  const api = useApi().signUp;

  const trackInit = async (aggregatorName: string, aggregatorUid?: string) => {
    let link;
    const aggregatorUidUpdated = aggregatorUid ?? uuid4();
    try {
      const payload = await api.aggregatorTrackingInit(aggregatorName, aggregatorUidUpdated);
      link = buildBitsUniversalLink(aggregatorName, payload.aggregatorUid);
    } catch (e) {
      // in case of error don't block redirect
      console.error(e);
      link = buildBitsUniversalLink(aggregatorName, aggregatorUidUpdated);
    }
    mixPanelInstance.trackAggregatorInit({
      aggregatorName,
      aggregatorUid: aggregatorUidUpdated,
    });
    return link;
  };

  return {
    trackInit,
  };
};
