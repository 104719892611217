import { use2FA } from "hooks/signup/use2FA";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import "../cassetteUI/signupFlow/SignUpFlow.scss";
import BitsIcon from "components/cassetteUI/common/BitsIcon";
import { useEffect } from "react";
import { useBitsUserContext } from "context/SignedInContext";
import { isCountryUs } from "utils/country";

export const MFA = () => {
  const {
    code,
    handleCodeChanged,
    handleSubmit,
    errorMessage,
    isSending,
    isResendAvailable,
    resendSMSVerificationCode,
    codeResent,
  } = use2FA();
  const navigate = useNavigate();
  const { phoneVerificationIdentifier } = useBitsUserContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (isCountryUs() || !phoneVerificationIdentifier) {
      navigate("/login");
    }
  }, []);

  return (
    <div id="login-container">
      <div className="unAuth-card">
        <BitsIcon />
        <Form autoComplete="on" noValidate>
          <div className="signup-header">
            <h5 className="title">{t("auth.forms.login.smsCode.title")}</h5>
            <p className="subtitle">{t("auth.forms.login.smsCode.subtitle")}</p>
          </div>
          <div className="form-wrapper">
            <Form.Group>
              <div className={errorMessage ? "input-error" : ""}>
                <input
                  type="text"
                  name="sms-code"
                  id="sms-code"
                  required
                  placeholder=""
                  defaultValue={code}
                  onChange={handleCodeChanged}
                  autoFocus
                  className="form-input"
                />
              </div>
              <div className="input-error-message" id="login-error-msg">
                {errorMessage}
              </div>
            </Form.Group>
            <Row className="mt-4">
              <Col>
                <button
                  id="send-sms-btn"
                  type="button"
                  className={(code?.length === 4 && "enabled") + " " + "form-input"}
                  disabled={code?.length !== 4}
                  onClick={handleSubmit}
                >
                  {!isSending ? `${t("auth.forms.continue")}` : <Spinner animation="border" />}
                </button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      {isResendAvailable && !isSending && (
        <Col className="login-page-switch">
          <div className="btn-switch">
            <Link to="#" id="resend-sms-code" onClick={resendSMSVerificationCode}>
              {t("auth.forms.login.smsCode.resend")}
            </Link>
          </div>
        </Col>
      )}
      {codeResent && (
        <Col className="login-page-switch">
          <p className="">{t("auth.forms.login.smsCode.resent")}</p>
        </Col>
      )}
    </div>
  );
  return (
    <Container className="pb-5 welcome-layout" id="login-container">
      <Form autoComplete="off" noValidate>
        <div className="form-wrapper">
          <Form.Group className="mt-5" controlId="code">
            <Form.Control
              type="text"
              name="sms-code"
              required
              placeholder={t("auth.forms.login.smsCode")}
              defaultValue={code}
              onChange={handleCodeChanged}
              autoFocus
            />
          </Form.Group>
          <Row>
            <Col>
              <Button
                id="phone-verification-submit-btn"
                variant="primary"
                type="button"
                size="lg"
                className="w-100 mt-4"
                disabled={code?.length !== 4}
              >
                {t("auth.forms.login.submitSMSCode")}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to="/forgotPassword" id="login-forgot-password">
                <Button variant="link" type="button" className="w-100 mt-4">
                  {t("auth.forms.forgotPassword")}
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </Form>
    </Container>
  );
};
