import { Spinner } from "react-bootstrap";
import "./Loading.scss";
import { Fragment } from "react";
import logo from "../../assets/logo/short-logo.webp";

const BitsLoading = ({ loading, message }: { loading: boolean; message: string | null }): JSX.Element | null => {
  if (!loading) {
    return null;
  }

  const renderMessage = (): JSX.Element | null => {
    if (!message) {
      return null;
    }
    return (
      <div className="bits-spinner-message-wrapper mb-4">
        <div className="bits-spinner-message">{message}</div>
        <div className="bits-spinner-polygon">
          <i className="bi bi-caret-down-fill" />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {renderMessage()}
      <div className="bits-spinner-wrapper mb-4">
        <Spinner animation="border" role="status" className="bits-spinner" />
        <div className="bits-spinner-logo">
          <img src={logo} alt="Bits" />
        </div>
      </div>
    </Fragment>
  );
};
export default BitsLoading;
