import { useEffect, useState } from "react";
import "./UpcomingPaymentsModal.scss";
import { useBitsUserContext } from "../../../context/SignedInContext";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { convertFloatToDinero, showInCurrency } from "../../../utils/money";
import { getCurrencySymbol } from "../../../utils/country";
import useApi from "../../../hooks/useApi";
import {
  CreditPaymentDetails,
  OrderPaymentDetails,
  PaymentListResponse,
  PaymentStatus,
  PaymentType,
  PaymentResponse,
  SubscriptionFeePaymentDetails,
} from "../../payment/PaymentApi";
import { TripleSpinner } from "../../ui/TripleSpinner";
import logger, { LogTagKey } from "../../../utils/logger";
import Icon, { IconType } from "../../common/Icon";
import { mixPanelInstance } from "../../../utils/analytics/mixpanel";
import { AnnualRenewalPeriod, PlanInterval } from "../../payment/SubscriptionApi";
import { formatUtcAsLocalDateTimeByEOD } from "../../../utils/date";

export const UpcomingModalId = "upcoming-payments-modal";

const ChangePaymentDetailsUrl = "/settings/paymentMethod";

const UpcomingPaymentsModal = ({
  isShown,
  onClose,
  bitsTurbo,
}: {
  isShown: boolean;
  onClose: () => void;
  bitsTurbo?: boolean;
}) => {
  const { wallet, userInfo, waitAndReloadWallet, hasOverduePayment, getNextPaymentDateTime, getLastPaymentDateTime } =
    useBitsUserContext();
  const { t, ready } = useTranslation();
  const paymentApi = useApi().payment;
  const navigate = useNavigate();
  const [payments, setPayments] = useState<PaymentListResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>();
  const [invoiceUrl, setInvoiceUrl] = useState<string | null>();
  const [isInvoiceOpen, setInvoiceOpen] = useState<boolean>(false);
  const [intervalHandle, setIntervalHandle] = useState<NodeJS.Timeout>();

  const handleClose = () => {
    setInvoiceOpen(false);
    onClose();
  };

  useEffect(() => {
    if (isInvoiceOpen) {
      const handle = setInterval(() => {
        paymentApi.getPayments([PaymentStatus.pending], [PaymentType.order, PaymentType.credit]).then((res) => {
          if (res.payments.length === 0) {
            waitAndReloadWallet();
            handleClose();
          }
        });
      }, 1000);
      setIntervalHandle(handle);
      return () => clearInterval(handle);
    } else {
      if (intervalHandle != null) {
        clearInterval(intervalHandle);
      }
    }
  }, [isInvoiceOpen]);

  useEffect(() => {
    if (isShown) {
      setLoadingMessage(t("upcomingPayments.loadingPayments"));
      setLoading(true);
      setInvoiceUrl(null);
      paymentApi
        .getPayments(
          [PaymentStatus.funded, PaymentStatus.pending, PaymentStatus.done],
          [PaymentType.subscriptionFee, PaymentType.order, PaymentType.credit]
        )
        .then(
          (res) => {
            const payments = !bitsTurbo
              ? res.payments.filter((payment: PaymentResponse) => !payment.details?.isPayInThreeOrder)
              : res.payments.filter((payment: PaymentResponse) => payment.details?.isPayInThreeOrder);
            setPayments({ ...res, payments });
            setLoading(false);
          },
          (err) => {
            setLoading(false);
            navigate("/paymentError");
            logger.error(err, LogTagKey.TkPaymentSystem, "Payment System getting payments", {
              membershipNumber: userInfo?.membershipNumber,
            });
          }
        );
    }
  }, [isShown]);

  const currency = getCurrencySymbol(wallet?.plan?.currency);

  const balance = !bitsTurbo
    ? convertFloatToDinero({
        amount: wallet?.balance?.currentBalance ?? 0,
        currency: wallet?.plan?.currency ?? "",
      })
    : convertFloatToDinero({
        amount: wallet?.bitsTurbo?.balance?.currentBalance ?? 0,
        currency: wallet?.plan?.currency ?? "",
      });

  const isOverdue = hasOverduePayment(bitsTurbo);

  const onPayNow = () => {
    setLoadingMessage(t("upcomingPayments.processingPayment"));
    setLoading(true);
    paymentApi.chargeFunded(bitsTurbo).then(
      (res) => {
        setLoading(false);
        if (res.payments?.some((p) => p.status === PaymentStatus.pending)) {
          const [payment] = res.payments;
          setInvoiceUrl(payment?.gateway?.checkoutUrl ?? null);
        } else {
          mixPanelInstance.trackPayNowPurchase();
          waitAndReloadWallet();
          handleClose();
        }
      },
      (err) => {
        setLoading(false);
        handleClose();
        navigate("/paymentError");
        logger.error(err, LogTagKey.TkPaymentSystem, "Payment System charging funded payments", {
          membershipNumber: userInfo?.membershipNumber,
        });
      }
    );
  };

  if (!ready) {
    return <TripleSpinner />;
  }

  const getDisplayPayments = () => {
    if (payments?.payments === undefined) return [];
    const subscriptionPayments = payments?.payments
      .filter((payment) => payment.type === PaymentType.subscriptionFee)
      .filter((payment) => (payment.details as SubscriptionFeePaymentDetails)?.billingPeriodEnd) // exclude very old payments that doesn't include billingPeriodEnd
      .sort((p1, p2) =>
        (p2?.details as SubscriptionFeePaymentDetails).billingPeriodEnd.localeCompare(
          (p1?.details as SubscriptionFeePaymentDetails).billingPeriodEnd
        )
      );

    const fundedPayments = payments?.payments
      .filter((payment) => payment.type !== PaymentType.subscriptionFee && payment.status !== PaymentStatus.done)
      .sort((p1, p2) =>
        p1?.scheduledDate && p2?.scheduledDate ? p1.scheduledDate.localeCompare(p2.scheduledDate) : 0
      );

    if (subscriptionPayments === undefined || subscriptionPayments?.length === 0) {
      return fundedPayments;
    }

    // handle delayed subscription
    if (subscriptionPayments?.length === 1 && subscriptionPayments[0].amount === 0) {
      // we only have 1 subscription payment of 0
      if (wallet?.plan != null) {
        if (wallet?.plan?.amount === 0) {
          // free plan - do not return subscription lines
          return fundedPayments;
        }
      }
    }
    // pending subscription fee
    const pendingSubscriptionPayments = subscriptionPayments
      .filter((payment) => payment.status === PaymentStatus.pending)
      .sort((p1, p2) =>
        (p2?.details as SubscriptionFeePaymentDetails).billingPeriodEnd.localeCompare(
          (p1?.details as SubscriptionFeePaymentDetails).billingPeriodEnd
        )
      );
    // we have non-free plan
    const primarySubscriptionPayment = {
      ...subscriptionPayments[0],
      amount: wallet?.plan?.amount ?? 0,
      details: {
        ...(subscriptionPayments[0].details as SubscriptionFeePaymentDetails),
        billingPeriodEnd: wallet?.nextPaymentDate as string,
      },
    };
    if (wallet?.supercharge) {
      const superchargeSubscriptionPayment = {
        ...subscriptionPayments[0],
        amount: wallet?.supercharge.plan?.amount ?? 0,
        details: {
          ...(subscriptionPayments[0].details as SubscriptionFeePaymentDetails),
          billingPeriodEnd: wallet?.nextPaymentDate as string,
        },
      };
      return [
        primarySubscriptionPayment,
        superchargeSubscriptionPayment,
        ...pendingSubscriptionPayments,
        ...fundedPayments,
      ];
    }
    return [primarySubscriptionPayment, ...pendingSubscriptionPayments, ...fundedPayments];
  };

  return (
    <Modal
      id={UpcomingModalId}
      show={isShown}
      onHide={handleClose}
      className={`upcoming-payments ${invoiceUrl ? "has-unauthorized" : ""}`}
      size="lg"
      data-cy="upcoming-payments-modal"
    >
      <Modal.Header closeButton={true} />
      <Modal.Body>
        <div className="header">{t("upcomingPayments.header")}</div>
        {loading && <TripleSpinner message={loadingMessage} />}
        {!loading && (
          <div className="dialog">
            {invoiceUrl && (
              <div className="content pending">
                <Row className="pending-row icon-row">
                  <Col>
                    <Icon size={63} type={IconType.exclamationWarning} />
                  </Col>
                </Row>
                <Row className="pending-row">
                  <Col className="pending-message">{t("upcomingPayments.pendingMessage")}</Col>
                </Row>
              </div>
            )}
            {!invoiceUrl && (
              <Row className="content">
                <div>
                  {getDisplayPayments()?.map((payment) => (
                    <UpcomingPaymentInfo
                      key={payment.id}
                      payment={payment}
                      currency={currency}
                      isOverdue={isOverdue}
                      dueDate={isOverdue ? getLastPaymentDateTime() : getNextPaymentDateTime()}
                      onAuthorizePayment={() => setInvoiceOpen(true)}
                    />
                  ))}
                </div>
              </Row>
            )}
            {!invoiceUrl && (
              <>
                <Row className="buttons">
                  <Col lg={2} />
                  <Col>
                    <Link to={ChangePaymentDetailsUrl}>
                      <Button variant="outline-dark">{t("upcomingPayments.updateBillingDetails")}</Button>
                    </Link>
                  </Col>
                  <Col lg={2} />
                </Row>
                {payments?.payments?.some((payment) => payment.status === PaymentStatus.funded) && (
                  <Row className="buttons">
                    <Col lg={2} />
                    <Col>
                      <Button
                        onClick={onPayNow}
                        variant={`${isOverdue ? "danger" : "primary"}`}
                        id="payButton"
                        data-cy="pay-button"
                      >
                        {t("upcomingPayments.payButton", { amount: showInCurrency(balance.toUnit(), currency) })}
                      </Button>
                    </Col>
                    <Col lg={2} />
                  </Row>
                )}
              </>
            )}
            {invoiceUrl && (
              <>
                <Row className="buttons">
                  <Col lg={2} />
                  <Col>
                    <a href={invoiceUrl} target="_blank" id="authorize-button" onClick={() => setInvoiceOpen(true)}>
                      <Button variant="primary">{t("upcomingPayments.authorizePayment")}</Button>
                    </a>
                  </Col>
                  <Col lg={2} />
                </Row>
              </>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

const UpcomingPaymentInfo = (props: {
  payment: PaymentResponse;
  currency: string;
  isOverdue: boolean;
  dueDate: string;
  onAuthorizePayment: () => void;
}) => {
  const { payment, currency, isOverdue, dueDate, onAuthorizePayment } = props;
  const { wallet } = useBitsUserContext();
  const { t } = useTranslation();

  const formatPrice = (amount: number, currency: string) => convertFloatToDinero({ amount, currency }).toUnit();

  const getPaymentLabel = () => {
    switch (payment.type) {
      case PaymentType.order:
        return (payment.details as OrderPaymentDetails)?.lines[0]?.productName;
      case PaymentType.credit:
        return (payment.details as CreditPaymentDetails)?.description;
      case PaymentType.subscriptionFee:
        if (!wallet?.plan) return "";
        switch (wallet?.plan.interval) {
          case PlanInterval.year:
            return wallet?.plan.renewalPeriod === AnnualRenewalPeriod.annually
              ? t("upcomingPayments.annuallyLabel")
              : t("upcomingPayments.biannuallyLabel");
          case PlanInterval.month:
          default:
            return t("upcomingPayments.monthlyLabel");
        }
      default:
        return "";
    }
  };

  return (
    <div className="payment-row" key={payment.id} data-cy="payment-row">
      <div className="product-name" data-cy="product-name">
        <div className="d-flex align-items-start">
          <div>
            {payment.status === PaymentStatus.pending && payment.gateway?.checkoutUrl && (
              <Icon size={30} type={IconType.exclamationWarning} />
            )}
            {getPaymentLabel()}
            <br />
            {isOverdue && (
              <span className="fs-6 overdue-label me-2" role="alert">
                {t("upcomingPayments.overdueLabel")}
              </span>
            )}
            {payment.type === PaymentType.subscriptionFee && (
              <span className="fs-6 bill-label">{t("upcomingPayments.billedAutomatically")}</span>
            )}
            {payment.status === PaymentStatus.pending && payment.gateway?.checkoutUrl && (
              <div className="authorize-button-line">
                <a href={payment.gateway?.checkoutUrl} target="_blank" onClick={onAuthorizePayment}>
                  <Button variant="primary">{t("upcomingPayments.authorizePayment")}</Button>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="product-price" data-cy="product-price">
        <span className={`fs-4 fw-bolder ${isOverdue && "overdue-text"}`}>
          {showInCurrency(formatPrice(payment.amount, payment.currency), currency)}
        </span>
        <br />
        {t("upcomingPayments.dueOn")}{" "}
        {isOverdue
          ? dueDate
          : payment?.type === PaymentType.subscriptionFee
          ? formatUtcAsLocalDateTimeByEOD((payment?.details as SubscriptionFeePaymentDetails).billingPeriodEnd)
          : formatUtcAsLocalDateTimeByEOD(payment?.scheduledDate)}
      </div>
    </div>
  );
};
export default UpcomingPaymentsModal;
