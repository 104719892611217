import { Col, Container, Row } from "react-bootstrap";
import { getCompanyName, getExternalLinks } from "../../../utils/country";
import { useTranslation } from "react-i18next";
import { OnlyIf } from "../../common/OnlyIf";
import AuthService from "../../auth/AuthService";
import { CountrySwitch } from "../../common/CountrySwitch";
import "./footer.scss";

export const Footer = () => {
  const { t } = useTranslation();
  const externalLinks = getExternalLinks();

  const {
    twitterUrl,
    instagramUrl,
    termsConditionsUrl,
    termsUrl,
    policyPrivacyUrl,
    complaints,
    rentReporting,
    bitsStore,
    bitsStoreGiftCardList,
    helpCentre,
    missionAndValues,
    productComparison,
    vulnerableCustomers,
    termsConditionsUrlSimplified,
    termsUrlSimplified,
    policyPrivacyUrlSimplified,
    complaintsSimplified,
    marketplaceUrl,
    risksBenefitsUrl,
    freeCreditReportUrl,
    regulatoryStatusUrl,
    trustpilotUrl,
    usaSiteUrl,
    scoreInAppUrl,
    creditCardFaqsUrl,
    creditCardTermsAndConditionsUrl,
    creditCardFXCalculatorUrl,
    creditCard3dsFaqsUrl,
    creditCardPrivacyPolicyUrl,
    creditCardHomeUrl,
    blogPosts,
    diversityAndInclusion,
  } = externalLinks;
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer" data-cy="footer">
      <Container>
        <Row className="nestedRow menu">
          <Col className="nestedCol">
            <span data-cy="company-title">{t("navigation.externalNavigation.company")}</span>
            <a href={scoreInAppUrl!} target="_blank" data-cy="score-in-app">
              {t("navigation.externalNavigation.scoreInApp")}
            </a>
            <a href={marketplaceUrl!} target="_blank" data-cy="marketplace">
              {t("navigation.externalNavigation.marketplace")}
            </a>
            <a href={rentReporting!} target="_blank" data-cy="rent-reporting">
              {t("navigation.externalNavigation.rentReporting")}
            </a>
            <a href={bitsStore!} target="_blank" data-cy="bits-store">
              {t("navigation.externalNavigation.bitsStore.howItWorks")}
            </a>
            <a href={bitsStoreGiftCardList!} target="_blank" data-cy="bits-store-gift-card-list">
              {t("navigation.externalNavigation.bitsStore.giftCardList")}
            </a>
            <a href={blogPosts!} target="_blank" data-cy="bits-blog-posts">
              {t("navigation.externalNavigation.blogPosts")}
            </a>
            {/* <a href={faqsUrl!} target="_blank" data-cy="faqs-link">
              {t("navigation.externalNavigation.faqs")}
            </a> */}
            <a href={risksBenefitsUrl!} target="_blank" data-cy="risks-benefits-link">
              {t("navigation.externalNavigation.risksBenefits")}
            </a>
            <a href={freeCreditReportUrl!} target="_blank" data-cy="free-credit-report-link">
              {t("navigation.externalNavigation.freeCreditReport")}
            </a>
            <a href={regulatoryStatusUrl!} target="_blank" data-cy="regulatory-status-link">
              {t("navigation.externalNavigation.regulatoryStatus")}
            </a>
            <a href={vulnerableCustomers!} target="_blank" data-cy="vulnerable-customers-link">
              {t("navigation.externalNavigation.vulnerableCustomers")}
            </a>
            <a href={productComparison!} target="_blank" data-cy="product-comparison-link">
              {t("navigation.externalNavigation.productComparison")}
            </a>
            <a href={missionAndValues!} target="_blank" data-cy="mission-and-values-link">
              {t("navigation.externalNavigation.missionAndValues")}
            </a>
            <a href={diversityAndInclusion!} target="_blank" data-cy="diversity-and-inclusion-link">
              {t("navigation.externalNavigation.diversityAndInclusion")}
            </a>
            <a href={helpCentre!} target="_blank" data-cy="help-centre-link">
              {t("navigation.externalNavigation.helpCentre")}
            </a>
          </Col>
          <Col className="nestedCol">
            <span data-cy="credit-card">{t("navigation.externalNavigation.creditCard")}</span>
            <a href={creditCardHomeUrl} target="_blank" data-cy="credit-card-home-link" rel="noopener noreferrer">
              {t("navigation.externalNavigation.creditCardHome")}
            </a>
            <a href={creditCardFaqsUrl} target="_blank" data-cy="credit-card-faqs-link" rel="noopener noreferrer">
              {t("navigation.externalNavigation.creditCardFaqs")}
            </a>
            <a
              href={creditCardTermsAndConditionsUrl}
              target="_blank"
              data-cy="credit-card-t-c-link"
              rel="noopener noreferrer"
            >
              {t("navigation.externalNavigation.creditCardT&C")}
            </a>
            <a
              href={creditCardFXCalculatorUrl}
              target="_blank"
              data-cy="credit-card-fx-calculator-link"
              rel="noopener noreferrer"
            >
              {t("navigation.externalNavigation.cardFXCalculator")}
            </a>
            <a
              href={creditCardPrivacyPolicyUrl}
              target="_blank"
              data-cy="credit-card-privacy-policy-link"
              rel="noopener noreferrer"
            >
              {t("navigation.externalNavigation.cardPrivacyPolicy")}
            </a>
            <a
              href={creditCard3dsFaqsUrl}
              target="_blank"
              data-cy="credit-card-3ds-faqs-link"
              rel="noopener noreferrer"
            >
              {t("navigation.externalNavigation.card3DSFAQS")}
            </a>
          </Col>
          <Col className="nestedCol">
            <span data-cy="legal-title">{t("navigation.externalNavigation.legal")}</span>
            <a href={termsConditionsUrl} target="_blank" data-cy="terms-of-use-link" rel="noopener noreferrer">
              {t("navigation.externalNavigation.termsOfUse")}
            </a>
            <a href={termsUrl} target="_blank" data-cy="store-card-terms-link" rel="noopener noreferrer">
              {t("navigation.externalNavigation.storeBitsCard")}
            </a>
            <a href={policyPrivacyUrl} target="_blank" data-cy="privacy-policy-link" rel="noopener noreferrer">
              {t("navigation.externalNavigation.privacyPolicy")}
            </a>
            <a href={complaints} target="_blank" data-cy="complaints-link" rel="noopener noreferrer">
              {t("navigation.externalNavigation.complaints")}
            </a>
          </Col>
          <Col className="nestedCol">
            <span data-cy="legal-title-simplified">{t("navigation.externalNavigation.legalSimplified")}</span>
            <a
              href={termsConditionsUrlSimplified}
              target="_blank"
              data-cy="terms-of-use-link-simplified"
              rel="noopener noreferrer"
            >
              {t("navigation.externalNavigation.termsOfUseSimplified")}
            </a>
            <a
              href={termsUrlSimplified}
              target="_blank"
              data-cy="store-card-terms-link-simplified"
              rel="noopener noreferrer"
            >
              {t("navigation.externalNavigation.storeBitsCardSimplified")}
            </a>
            <a
              href={policyPrivacyUrlSimplified}
              target="_blank"
              data-cy="privacy-policy-link-simplified"
              rel="noopener noreferrer"
            >
              {t("navigation.externalNavigation.privacyPolicySimplified")}
            </a>
            <a
              href={complaintsSimplified}
              target="_blank"
              data-cy="complaints-link-simplified"
              rel="noopener noreferrer"
            >
              {t("navigation.externalNavigation.complaintsSimplified")}
            </a>
          </Col>
          <Col className="nestedCol">
            <span data-cy="content-title">{t("navigation.externalNavigation.content")}</span>
            <a href={usaSiteUrl!} data-cy="trustpilot-link" target="_blank" rel="noopener noreferrer">
              {t("navigation.externalNavigation.usaSite")}
            </a>
            <a href={twitterUrl!} data-cy="twitter-link" target="_blank" rel="noopener noreferrer">
              {t("navigation.externalNavigation.twitter")}
            </a>
            <a href={instagramUrl!} data-cy="instagram-link" target="_blank" rel="noopener noreferrer">
              {t("navigation.externalNavigation.instagram")}
            </a>
            <a href={trustpilotUrl!} data-cy="trustpilot-link" target="_blank" rel="noopener noreferrer">
              {t("navigation.externalNavigation.trustpilot")}
            </a>
          </Col>
        </Row>
        <Row className="nestedRow country-selector">
          <Col className="nestedCol">
            <OnlyIf condition={!AuthService.isAuthenticated()}>
              <div className="country-switch-wrapper">
                <CountrySwitch />
              </div>
            </OnlyIf>
          </Col>
        </Row>
        <Row>
          <p data-cy="company-name-title">{t("common.footer.uk.companyName")}</p>
          <p data-cy="copy-right">
            {getCompanyName()} 2018 - {currentYear}
          </p>
          <p className="address" data-cy="address">
            {t("common.footer.uk.address")}
          </p>
          <p className="support">
            <a data-cy="email-support" href={`mailto:${t("common.footer.uk.emailSupport")}`}>
              {t("common.footer.uk.emailSupport")}
            </a>
          </p>
          <p className="support">
            <a data-cy="contact-number-support" href={`tel:${t("common.footer.uk.contactSupport")}`}>
              {t("common.footer.uk.contactSupport")}
            </a>
          </p>
          <p>
            <a data-cy="contact-number-details">{t("common.footer.uk.contactNumberDetails")}</a>
          </p>
          <p>
            <a data-cy="contact-number-details">{t("common.footer.uk.languageSupported")}</a>
          </p>
          <p data-cy="uk-footer-legal-1">{t("common.footer.uk.text1")}</p>
          <p data-cy="uk-footer-legal-2">{t("common.footer.uk.text2")}</p>
          <p data-cy="uk-footer-legal-3">{t("common.footer.uk.text3")}</p>
        </Row>
      </Container>
    </footer>
  );
};
