import AuthService from "components/auth/AuthService";
import { useBitsUserContext } from "context/SignedInContext";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isCountryUk } from "utils/country";
import { v4 as uuid } from "uuid";
export const use2FA = () => {
  const { signUp } = useApi();
  const isUK = isCountryUk();
  const navigateLocation = isUK ? "/dashboard" : "/store";
  const navigate = useNavigate();
  const location = useLocation();
  const { phoneVerificationIdentifier, setPhoneVerificationIdentifier, accessToken, setAccessToken } =
    useBitsUserContext();
  const [errorMessage, setErrorMessage] = useState("");
  const [code, setCode] = useState<string>("");
  const [isSending, setSending] = useState(false);
  const [isResendAvailable, setResendAvailable] = useState(true);
  const [resendCounter, setResendCounter] = useState(0);
  const [currentInterval, setCurrentInterval] = useState<any>(null);
  const [codeResent, setCodeResent] = useState(false);

  useEffect(() => {
    return () => {
      if (currentInterval) {
        clearInterval(currentInterval);
        setCurrentInterval(null);
      }
    };
  }, []);

  useEffect(() => {
    if (resendCounter === 10) {
      if (currentInterval) {
        clearInterval(currentInterval);
      }
      setCurrentInterval(null);
      setResendCounter(0);
      setCodeResent(false);
      setResendAvailable(true);
    }
  }, [resendCounter]);

  const resendSMSVerificationCode = async () => {
    try {
      setResendAvailable(false);
      if (!accessToken) {
        return navigate("/login");
      }
      await requestSMSVerificationCode(accessToken);
      setCodeResent(true);
      setCurrentInterval(
        setInterval(() => {
          setResendCounter((resendCounter) => resendCounter + 1);
        }, 1000)
      );
    } catch (err: any) {
      console.error(err);
      setResendAvailable(true);
    }
  };
  const requestSMSVerificationCode = async (accessToken: string) => {
    const userInfo = await (await signUp.unAuthorizedFetchProfile(accessToken)).json();
    if (userInfo?.phoneNumber) {
      const { phoneNumber } = userInfo;
      const phoneVerificationIdentifier = uuid();
      setPhoneVerificationIdentifier(phoneVerificationIdentifier);
      const result = await signUp.phoneVerifyRequest(phoneNumber?.replace(/ /g, ""), phoneVerificationIdentifier);
      if (result?.status !== "Success") {
        throw new Error(`Phone verification failed to be requested. ${result?.error ?? ""}`);
      }
      navigate("/smsverification");
    } else {
      throw new Error(`User info couldn't be fetch`);
    }
  };

  const verifyCode = async () => {
    setSending(true);
    try {
      if (!phoneVerificationIdentifier || !accessToken) {
        return navigate("/login");
      }
      const result = await signUp.phoneVerify(code, phoneVerificationIdentifier);
      if (result?.status === "Success" && result.data?.verified === true) {
        AuthService.saveAuthToken(accessToken);
        setAccessToken(null);
        if (location?.state?.from) {
          navigate(location?.state?.from);
        } else {
          navigate(navigateLocation);
        }
      } else {
        setErrorMessage("Invalid code provided");
      }
    } catch (err: any) {
      console.error(err);
      setErrorMessage("Invalid code provided");
      //sentry;
    } finally {
      setSending(false);
    }
  };

  return {
    requestSMSVerificationCode,
    verifyCode,
    code,
    handleCodeChanged: (event: any) => setCode(event.target.value),
    handleSubmit: () => verifyCode(),
    errorMessage,
    isSending,
    resendSMSVerificationCode,
    isResendAvailable,
    codeResent,
  };
};
