import { Route, Routes, useNavigate } from "react-router-dom";
import "./Checkout.scss";
import "../cart/Cart.scss";
import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { isCountryUk } from "../../utils/country";
import { AddressInput, Money, PaymentGatewayType } from "./Checkout.types";
import { useBitsUserContext } from "../../context/SignedInContext";
import { useStickyState } from "../../hooks/useStickyState";
import loadable from "@loadable/component";
import { Address } from "../signup/SignUpApi";
import { isUserCreditLineActiveOrPending } from "../auth/TagsApi";
import AccountSetupModal from "../store/account-setup/AccountSetupModal";
import SubscriptionModal from "./subscription-modal/SubscriptionModal";
import { isSubscriptionActive } from "../payment/PaymentApi";
import { transformAddressInputToAddress } from "./CheckoutUtils";
import { useCheckout } from "../../hooks/useCheckout";
import { OrderSummary } from "../cart/OrderSummary";
import useSessionStorage from "hooks/useSessionStorage";
import { useContentfulContext } from "../../context/ContentfulContext";

export const IS_PAY_IN_THREE_ORDER_KEY = "IS_PAY_IN_THREE_ORDER";
const ContentWrapper = loadable(() => import(/* webpackChunkName: "ContentWrapper" */ "../common/ContentWrapper"), {
  resolveComponent: (components) => components.ContentWrapper,
});

const CheckoutFormMain = loadable(
  () => import(/* webpackChunkName: "CheckoutFormMain" */ "./checkout-form/CheckoutFormMain"),
  {
    resolveComponent: (components) => components.CheckoutFormMain,
  }
);

const CheckoutComplete = loadable(
  () => import(/* webpackChunkName: "CheckoutComplete" */ "./complete/CheckoutComplete")
);

const Loading = loadable(() => import(/* webpackChunkName: "Loading" */ "../common/Loading"));

const CheckoutErrorForm = loadable(() => import(/* webpackChunkName: "CheckoutErrorForm" */ "./error/CheckoutError"));

const CheckoutKyc = loadable(() => import(/* webpackChunkName: "CheckoutKyc" */ "./kyc/CheckoutKyc"), {
  resolveComponent: (components) => components.CheckoutKyc,
});

export enum PaymentMethod {
  Debit,
  Credit,
  PayInThree,
}

export interface Pricing {
  debit: Money;
  credit?: Money;
}

export const Checkout = () => {
  const { userInfo, hasOverduePayment, userTags, wallet, isPayInThree } = useBitsUserContext();
  const { setItem } = useSessionStorage();
  const [paymentMethod, setPaymentMethod] = useStickyState<PaymentMethod>(PaymentMethod.Credit, "checkoutMode");
  const [error, setError] = useState("");
  const [shippingAddress, setShippingAddress] = useState<AddressInput | undefined>(undefined);
  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(undefined);
  const [isAccountSetupModalShown, setIsAccountSetupModalShown] = useState<boolean>(false);
  const [isSubscriptionModalShown, setIsSubscriptionModalShown] = useState<boolean>(false);

  const {
    isCartLoading,
    createPricing,
    createCheckoutPayment,
    debitPricing,
    creditPricing,
    shoppingCartCheckout,
    updateCheckoutShippingAddress,
    confirmOrder,
    setShoppingCartCheckout,
    isVelocityError,
    isAddressError,
    isCreditLimitError,
    setIsVelocityError,
    setIsAddressError,
    setIsCreditLimitError,
    kycRequired,
    kycDeclined,
  } = useCheckout();

  const { content } = useContentfulContext();

  const enableIDVerificationOnCheckout = content?.featureFlags?.enableIDVerificationOnCheckout;

  useEffect(() => {
    const selectedAddress: Address | undefined = transformAddressInputToAddress(shoppingCartCheckout?.shippingAddress);
    setSelectedAddress(selectedAddress);
  }, [shoppingCartCheckout?.shippingAddress]);
  const navigate = useNavigate();

  useEffect(() => {
    if (shippingAddress) {
      updateCheckoutShippingAddress(shippingAddress);
    }
  }, [shippingAddress]);

  useEffect(() => {
    if (!enableIDVerificationOnCheckout) {
      return;
    }

    if (kycRequired || kycDeclined) {
      navigate("./kyc");
    }
  }, [kycRequired, kycDeclined]);

  if (!userInfo?.membershipNumber) {
    return null;
  }

  const createPayment = async (gateway: PaymentGatewayType): Promise<boolean> => {
    const paymentResult = await createCheckoutPayment(gateway);
    if (paymentResult?.checkout != null) {
      setShoppingCartCheckout(paymentResult.checkout);
      return true;
    } else {
      return false;
    }
  };

  const handleAccountSetupModalClose = () => {
    setIsAccountSetupModalShown(false);
  };

  const handleSubscriptionModalClose = () => {
    setIsSubscriptionModalShown(false);
  };

  const proceedWithPrimaryAddress = async () => {
    const { address1, address2, city, state, postCode, country } = userInfo ?? {};
    const { streetAddress1, streetAddress2, countryArea, postalCode, firstName, lastName, phone, companyName } =
      shoppingCartCheckout?.shippingAddress ?? {};
    if (
      address1 !== streetAddress1 ||
      address2 !== streetAddress2 ||
      city?.toLowerCase() !== shoppingCartCheckout?.shippingAddress?.city?.toLowerCase() ||
      postCode !== postalCode ||
      state !== countryArea
    ) {
      return setShippingAddress({
        firstName: firstName ?? "",
        lastName: lastName ?? "",
        companyName: companyName ?? "",
        streetAddress1: address1 ?? "",
        streetAddress2: address2 ?? "",
        city: city ?? "",
        cityArea: state ?? "",
        postalCode: postCode ?? "",
        country: country ?? "",
        countryArea: state ?? "",
        phone: phone ?? "",
      });
    }
    await proceedCheckoutPayment();
  };

  const validateAccountSetupModal = () => {
    if (!isSubscriptionActive(wallet)) {
      setIsSubscriptionModalShown(true);
      return false;
    }
    if (paymentMethod === PaymentMethod.Credit && userTags != null && !isUserCreditLineActiveOrPending(userTags)) {
      setIsAccountSetupModalShown(true);
      return false;
    }
    return true;
  };

  const proceedCheckoutPayment = async () => {
    // Show setup dialogs if needed
    if (!validateAccountSetupModal()) {
      return;
    }
    // Create payment
    switch (paymentMethod) {
      case PaymentMethod.Debit:
        await createPayment(PaymentGatewayType.Debit);
        break;
      case PaymentMethod.Credit:
        !hasOverduePayment() && (await createPayment(PaymentGatewayType.Installments));
        break;
      default:
        break;
    }
    // Proceed order payment
    try {
      const confirmation = await confirmOrder(false);
      if (confirmation != null) {
        setItem(IS_PAY_IN_THREE_ORDER_KEY, isPayInThree.toString());
        window.location.href = confirmation.checkout_url;
      }
    } catch (e) {
      // do nothing as errors have already been handled
    }
  };

  const onBackToStoreSuccess = () => {
    navigate("/store");
  };

  const onTryAgainAfterError = () => {
    setIsVelocityError(false);
    setIsCreditLimitError(false);
    setIsAddressError(false);
    navigate("");
    setError("");
  };

  const onBackToStore = () => {
    setIsVelocityError(false);
    setIsCreditLimitError(false);
    setIsAddressError(false);
    navigate("/store");
    setError("");
  };

  const onPaymentBackAfterError = () => {
    navigate("");
    setError("");
  };

  const onPaymentNextButtonClick = () => {
    validateAccountSetupModal();
  };

  const pricing = createPricing(shoppingCartCheckout ?? null, paymentMethod);
  const isUK = isCountryUk();

  return (
    <ContentWrapper shadow={isUK ? false : true} className={(isUK && "mt-0") + " " + "checkout mb-3 mt-0"}>
      <Loading loading={isCartLoading} message={null} />
      <Row className={`${isCartLoading ? "invisible" : ""}`}>
        <Col className={`col-12 col-sm-12 ${error ? "col-md-12 col-lg-12" : "col-md-6 col-lg-6"}`}>
          <Routes>
            <Route path="kyc" element={<CheckoutKyc />} />
            <Route
              index
              element={
                <CheckoutFormMain
                  debitPricing={debitPricing}
                  creditPricing={creditPricing}
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                  onPaymentNextButtonClick={onPaymentNextButtonClick}
                  checkout={shoppingCartCheckout}
                  onConfirm={proceedCheckoutPayment}
                  setShippingAddress={setShippingAddress}
                  onProceedWithPrimaryAddress={proceedWithPrimaryAddress}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                />
              }
            />
            <Route
              path="complete"
              element={
                <CheckoutComplete
                  checkout={shoppingCartCheckout}
                  pricing={pricing}
                  onBackToStore={onBackToStoreSuccess}
                  paymentMethod={paymentMethod}
                  confirmOrder={confirmOrder}
                />
              }
            />
            <Route
              path="error"
              element={
                <CheckoutErrorForm
                  onTryAgain={onTryAgainAfterError}
                  onBackToPaymentSelection={onPaymentBackAfterError}
                  isVelocityError={isVelocityError}
                  isCreditLimitError={isCreditLimitError}
                  isAddressError={isAddressError}
                  onBackToStore={onBackToStore}
                />
              }
            />
          </Routes>
        </Col>
        {!error && (
          <>
            <Col className="d-sm-none d-md-none col-lg-1 col-xl-1 col-xxl-1" />
            <Col className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
              <OrderSummary />
            </Col>
          </>
        )}
      </Row>
      <AccountSetupModal isShown={isAccountSetupModalShown} onClose={handleAccountSetupModalClose} />
      <SubscriptionModal isShown={isSubscriptionModalShown} onClose={handleSubscriptionModalClose} />
    </ContentWrapper>
  );
};
